import { SendEncryptedPhotoMessageDto } from "@nearD/im-js-sdk/lib/api/chatroom/dto/e2ee/send-encrypted-photo-message.dto";
import { SendPhotoMessageDto } from "@nearD/im-js-sdk/lib/api/chatroom/dto/send-photo-message.dto";
import { RoomVM } from "modules/chatroom/view-models";
import getImClient from "utils/imClient";
import SendMsgHandler from ".";
import { sendImgMsg } from "../actions";
import { transformPhotoMessageDto } from "../transformers";
import { MessageVM } from "../view-models";

type SendImgMsgAction = ReturnType<typeof sendImgMsg>;

class SendImgMsgHandler extends SendMsgHandler<
  SendImgMsgAction,
  SendPhotoMessageDto | SendEncryptedPhotoMessageDto
> {
  protected async getMsgDto(
    action: SendImgMsgAction,
    msgTempId: string,
    replyMsgId?: string
  ): Promise<SendPhotoMessageDto | SendEncryptedPhotoMessageDto | null> {
    const { roomId, fileId } = action.payload;
    return getImClient().chatroom.sendPhotoMessage(
      roomId,
      msgTempId,
      fileId,
      undefined,
      replyMsgId
    );
  }

  protected async transformMsgDto(
    msgDto: SendPhotoMessageDto | SendEncryptedPhotoMessageDto,
    action: SendImgMsgAction,
    room: RoomVM,
    sendTime: number
  ): Promise<MessageVM> {
    return transformPhotoMessageDto(
      "",
      msgDto,
      action.payload.img,
      room.myProfileId,
      sendTime
    );
  }
}

const sendImgMsgHandler = new SendImgMsgHandler();
export default sendImgMsgHandler;
