import { isNotNil } from "@nearD/toolkit/build/main/lib/val/is-not-nil";
import { createSelector } from "@reduxjs/toolkit";
import { getProfileId } from "../profile/selectors";
import { AppState } from "../rootReducer";
import { isGroupChat, isUserChat } from "./utils";

function getState(state: AppState) {
  return state.chatroom;
}
export const getRoomId = (state: AppState, props: { roomId: string }) =>
  props.roomId;

const roomsSelector = createSelector([getState], (state) => state.rooms);
export const orderedRoomIdsSelector = createSelector(
  [getState],
  (state) => state.orderedList
);
export const roomMembersSelector = createSelector(
  [getState, getRoomId],
  (state, roomId) => state.members[roomId]
);
export const unreadCountsSelector = createSelector(
  [getState],
  (state) => state.unreadCount
);
const typingsSelector = createSelector([getState], (state) => state.typings);

export const roomMemberSelector = createSelector(
  [getState, getRoomId, getProfileId],
  (state, roomId, profileId) => state.members[roomId]?.[profileId]
);

export const roomListSelector = createSelector(
  [roomsSelector, orderedRoomIdsSelector],
  (roomMap, orderedList) =>
    orderedList.map((id) => roomMap[id]).filter(isNotNil)
);

export const roomSelector = createSelector(
  [roomsSelector, getRoomId],
  (roomMap, roomId) => {
    const room = roomMap[roomId] ?? undefined;
    if (!room || !room?.id) {
      return undefined;
    }
    return room;
  }
);

export const unreadCountSelector = createSelector(
  [unreadCountsSelector, getRoomId],
  (unreadCounts, roomId) => {
    return unreadCounts?.[roomId];
  }
);

export const typingProfileIdsSelector = createSelector(
  [typingsSelector, getRoomId],
  (typings, roomId) => typings[roomId]
);

export const userRoomsSelector = createSelector([roomListSelector], (rooms) =>
  rooms.filter(isUserChat)
);

export const groupRoomsSelector = createSelector([roomListSelector], (rooms) =>
  rooms.filter(isGroupChat)
);

export const isRoomListInitedSelector = createSelector(
  [getState],
  (state) => state.isInited
);

export const pinnedRoomIdsSelector = createSelector(
  [getState],
  (state) => state.pinnedRoomIds
);
