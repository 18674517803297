import { createAction } from "@reduxjs/toolkit";
import { MessageVM } from "../message/view-models";

export const storeIsNotificationGranted = createAction(
  "notification/StoreIsNotificationGranted",
  (isGranted: boolean) => ({
    payload: {
      isGranted,
    },
  })
);
export const notifyMsg = createAction(
  "notification/NotifyMsg",
  (roomId: string, msg: MessageVM) => ({
    payload: {
      roomId,
      msg,
    },
  })
);

export const addUnnoticedCount = createAction("notification/AddUnnoticedCount");
export const clearUnnoticedCount = createAction(
  "notification/ClearUnnoticedCount"
);

export const setIsAllowed = createAction(
  "notification/SetIsAllowed",
  (isAllowed: boolean) => ({
    payload: { isAllowed },
  })
);

export const storeIsAllowSound = createAction(
  "notification/StoreIsAllowSound",
  (isAllowed: boolean) => ({
    payload: { isAllowed },
  })
);
