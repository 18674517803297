import { isGroupChat } from "modules/chatroom/utils";
import { all, put, select, takeEvery } from 'typed-redux-saga';
import { roomSelector } from "../../chatroom/selectors";
import { AppState } from "../../rootReducer";
import { fetchPinnedMsg, fetchRoomPinnedMsgs } from "../actions";

export default function* watchFetchRoomPinnedMsgs() {
  yield* takeEvery(fetchRoomPinnedMsgs, function* ({ payload: { roomId } }) {
    const room = yield* select((state: AppState) => roomSelector(state, { roomId }));
    if (room === undefined) {
      return;
    }
    if (isGroupChat(room) && room.pinnedMsgIds.length > 0) {
      yield* all(
        room.pinnedMsgIds.map((msgId) => put(fetchPinnedMsg(roomId, msgId)))
      );
    }
  });
}
