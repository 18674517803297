import { fetchMySummary } from "modules/me/watchers";
import { all, put, takeEvery } from "typed-redux-saga";
import { call } from "typed-redux-saga";
import getImClient from "utils/imClient";
import { watchChannel } from "utils/saga-utils";
import * as actions from "./actions";
import * as api from "./api";
import createProfileChannel from "./channel";
import { transformFirestoreProfile, transformProfile } from "./transformers";

function* watchAddProfile() {
  yield* takeEvery(actions.addProfile, function* (action) {
    try {
      const response = yield* call(api.fetchProfile, {
        profileId: action.payload.profileId,
      });
      const result = transformProfile(Object.values(response.data.profiles)[0]);
      yield* put(actions.storeProfile(result));
    } catch (error) {
      console.error(error);
    }
  });
}

function* watchUpdateProfile() {
  yield* takeEvery(actions.updateProfile, function* (action) {
    const { profile, status } = transformFirestoreProfile(
      action.payload.profile
    );
    yield* all([
      put(actions.storeProfile(profile)),
      put(actions.storeProfileStatus(status)),
    ]);
  });
}

function* watchCheckProfileExists() {
  yield* takeEvery(actions.checkProfileExists, function* (action) {
    const { profileId } = action.payload;
    try {
      yield* call(api.fetchProfile, { profileId });
      yield* put(actions.storeProfileExistence(profileId, true));
    } catch (error) {
      if ((error as any)?.data.code === "ER0001") {
        yield* put(actions.storeProfileExistence(profileId, false));
      }
      console.error(error);
    }
  });
}

function* watchFollowProfile() {
  yield* takeEvery(actions.followProfile, function* (action) {
    const { myProfileId, targetProfileId } = action.payload;
    try {
      yield* call(api.followProfile, { myProfileId, targetProfileId });
      yield* call(fetchMySummary);
    } catch (error) {
      console.error(error);
    }
  });
}

function* watchUnfollowProfile() {
  yield* takeEvery(actions.unfollowProfile, function* (action) {
    const { profileId } = action.payload;
    try {
      yield* call(api.unfollowProfile, { targetProfileId: profileId });
      yield* call(fetchMySummary);
    } catch (error) {
      console.error(error);
    }
  });
}

const profileWatchers = [
  watchChannel(createProfileChannel(getImClient())),
  watchAddProfile,
  watchUpdateProfile,
  watchCheckProfileExists,
  watchFollowProfile,
  watchUnfollowProfile,
];

export default profileWatchers;
