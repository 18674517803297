export default function Toast({
  iconClass,
  text,
}: {
  iconClass: string;
  text: string;
}) {
  return (
    <div className="flex p-2 font-primary font-bold items-center">
      <div className="flex-none mr-2 rounded-full bg-red-lv1 text-white w-12 h-12 flex items-center justify-center">
        <i className={`${iconClass} text-3xl`}></i>
      </div>
      <div className="flex-1">{text}</div>
    </div>
  );
}
