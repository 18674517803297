import { groupResourceSelector } from "modules/resource/selectors";
import { AppState } from "modules/rootReducer";
import { call, select } from "typed-redux-saga";

export default function* getGroup(groupId: number) {
  try {
    let group = yield* select((state: AppState) =>
      groupResourceSelector(state, { id: groupId })
    );
    if (group === undefined) {
      const { fetchGroup } = yield* call(
        () => import("modules/group/api/fetchGroup")
      );
      const response = yield* call(fetchGroup, { groupId });
      group = Object.values(response.data.groups)[0];
    }
    return group;
  } catch (error) {
    console.error(error);
    return null;
  }
}
