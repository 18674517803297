import { storeProfiles } from "modules/profile/actions";
import { profileSelector } from "modules/profile/selectors";
import { AppState } from "modules/rootReducer";
import { SagaIterator } from "redux-saga";
import { put, select, takeEvery } from 'typed-redux-saga';
import { storeRooms } from "../actions";
import { userRoomsSelector } from "../selectors";
import { RoomVM } from "../view-models";

export default function* watchStoreProfiles() {
  // update user rooms' names if profiles update
  yield* takeEvery(
    storeProfiles,
    function* ({ payload: { profiles } }): SagaIterator {
      const userRooms = yield* select(userRoomsSelector);
      const profileIds = profiles.map((profile) => profile.id);
      const matchedUserRooms = userRooms.filter((room) =>
        profileIds.includes(room.participantProfileId)
      );
      const pendingUserRooms: RoomVM[] = [];
      for (const userRoom of matchedUserRooms) {
        const profile = yield* select((state: AppState) =>
          profileSelector(state, { profileId: userRoom.participantProfileId })
        );
        if (profile.name !== userRoom.name) {
          pendingUserRooms.push({ ...userRoom, name: profile.name });
        }
      }
      if (pendingUserRooms.length > 0) {
        yield* put(storeRooms(pendingUserRooms));
      }
    }
  );
}
