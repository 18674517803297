import { platformUrlsSelector } from "modules/platform/selectors/platformUrlsSelector";
import { platformTypeSelector } from "modules/platform/selectors/platformTypeSelector";
import { SagaIterator } from "redux-saga";
import { unregister as unregisterServiceWorker } from "serviceWorker";
import { call, select, takeEvery } from "typed-redux-saga";
import getImClient from "utils/imClient";
import { logout } from "../actions";

export default function* watchLogout() {
  yield* takeEvery(logout, function* (): SagaIterator {
    const imClient = getImClient();
    const urls = yield* select(platformUrlsSelector);
    const platform = yield* select(platformTypeSelector);
    yield* call(imClient.signOut);
    unregisterServiceWorker();
    const logoutUrl = new URL(urls.logoutUrl);
    if (platform === "nearD") {
      const redirectUrl = new URL("/login", urls.base).toString();
      logoutUrl.searchParams.set("redirect_uri", redirectUrl);
    }
    window.location.href = logoutUrl.toString();
  });
}
