import { UnreadCountData } from "@nearD/im-js-sdk/lib/events/im.events";
import {
  FirestoreChatroomMember,
  SenderInfo,
} from "@nearD/im-js-sdk/lib/im/models";
import { ChatroomState } from "@nearD/im-js-sdk/lib/store/modules/room/memory";
import { createAction } from "@reduxjs/toolkit";
import { ProfileVM } from "modules/profile/view-models";
import { RoomVM } from "./view-models";

export const upsertRooms = createAction(
  "chatroom/UpsertRooms",
  (rooms: ChatroomState[]) => ({
    payload: { rooms },
  })
);

export const storeRooms = createAction(
  "chatroom/StoreRooms",
  (rooms: RoomVM[]) => ({
    payload: {
      rooms,
    },
  })
);

export const delRoom = createAction("chatroom/DelRoom", (roomId: string) => ({
  payload: {
    roomId,
  },
}));

export const quitRoom = createAction("chatroom/QuitRoom", (roomId: string) => ({
  payload: {
    roomId,
  },
}));

export const storeRoomMembers = createAction(
  "chatroom/StoreRoomMembers",
  (roomId: string, members: FirestoreChatroomMember[]) => ({
    payload: {
      roomId,
      members,
    },
  })
);

export const delRoomMember = createAction(
  "chatroom/DelRoomMember",
  (roomId: string, memberId: number) => ({
    payload: {
      roomId,
      memberId,
    },
  })
);

export const createUserRoom = createAction(
  "chatroom/CreateUserRoom",
  (
    targetProfile: ProfileVM,
    myProfileId: number,
    platform: "uwants" | "nearD"
  ) => ({
    payload: {
      targetProfile,
      platform,
      myProfileId,
    },
  })
);

export const updateMemberTyping = createAction(
  "chatroom/UpdateMemberTyping",
  (roomId: string, sender: SenderInfo, type: "add" | "del") => ({
    payload: {
      roomId,
      sender,
      type,
    },
  })
);

export const storeMemberTyping = createAction(
  "chatroom/StoreMemberTyping",
  (roomId: string, profileId: number, type: "add" | "del") => ({
    payload: {
      roomId,
      profileId,
      type,
    },
  })
);

export const storeRoomSetting = createAction(
  "chatroom/StoreRoomSetting",
  (roomId: string, isMuted: boolean) => ({
    payload: {
      roomId,
      isMuted,
    },
  })
);

export const storeUnreadCounts = createAction(
  "chatroom/StoreUnreadCounts",
  (counts: UnreadCountData[]) => ({
    payload: { counts },
  })
);

export const storeIsRoomListInited = createAction(
  "chatroom/StoreIsRoomListInited",
  (isInited: boolean) => ({
    payload: { isInited },
  })
);

export const updateRoomMuteSetting = createAction(
  "chatroom/UpdateRoomMuteSetting",
  (roomId: string, isMute: boolean) => ({ payload: { roomId, isMute } })
);

export const pinRoomMsg = createAction(
  "chatroom/PinRoomMsg",
  (roomId: string, msgId: string) => ({ payload: { roomId, msgId } })
);

export const unpinRoomMsg = createAction(
  "chatroom/UnpinRoomMsg",
  (roomId: string, msgId: string) => ({ payload: { roomId, msgId } })
);

export const updatePinnedRooms = createAction(
  "chatroom/UpdatePinnedRooms",
  (roomIds: string[]) => ({ payload: { roomIds } })
);

export const pinRoom = createAction("chatroom/PinRoom", (roomId: string) => ({
  payload: { roomId },
}));

export const unpinRoom = createAction(
  "chatroom/UnpinRoom",
  (roomId: string) => ({
    payload: { roomId },
  })
);

export const muteMember = createAction(
  "chatroom/MuteMember",
  (roomId: string, targetProfileId: number, muteHours: number) => ({
    payload: { roomId, targetProfileId, muteHours },
  })
);

export const unMuteMember = createAction(
  "chatroom/UnMuteMember",
  (roomId: string, targetProfileId: number) => ({
    payload: { roomId, targetProfileId },
  })
);
