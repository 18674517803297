import PlatformState from "./state";
import env from "utils/env";
import { buildLoginUrl } from "utils/url";

const settings: { [type: string]: PlatformState } = {
  uwants: {
    name: "Uwants",
    type: "uwants",
    theme: {
      logo: "/images/uwants.png",
      logoSize: {
        width: 136,
        height: 40,
      },
      icon: "/platform/uwants/logo192.png",
      loading: "/images/uwants_loading.gif",
      welcomeImg: "/images/welcome_image/uwants.png",
      errorImg: "/images/error_image/uwants.png",
      scrollToBtmColor: "#ff7500",
      btnBackgroundClass: "bg-theme-uwants",
      textClass: "text-theme-uwants",
      borderClass: "border-theme-uwants",
      msgBackgroundClass: "bg-msg-uwants",
      showGroupIconInAvatar: true,
      switch: {
        onHandleColor: "#ff7502",
        onColor: "#eaeaea",
        offHandleColor: "#7f7f7f",
        offColor: "#eaeaea",
      },
    },
    settings: {
      hasMultipleProfiles: false,
      showOneAvatarInSingleChat: true,
      canSearchGroups: false,
    },
    urls: {
      base: env.uwantsMessengerUrl,
      home: env.uwantsWebsiteUrl,
      contactUs: `${env.uwantsWebsiteUrl}/contactus.php`,
      privacy: `${env.uwantsWebsiteUrl}/privacy.html`,
      terms: `${env.uwantsWebsiteUrl}/legal.html`,
      cookiesDomain: new URL(env.uwantsWebsiteUrl).hostname,
      loginUrl: env.uwantsLoginFailUrl,
      logoutUrl: env.uwantsLogoutUrl,
    },
    translationKeys: {
      emptyRoomList: "im.uwants-room-list-empty-text",
      createChatDesc: "im.uwants-create-chat-desc",
    },
    tracking: {
      ga: env.uwantsGA,
    },
  },
  nearD: {
    name: "nearD",
    type: "nearD",
    theme: {
      logo: "/images/neard.svg",
      logoSize: {
        width: 132,
        height: 48,
      },
      icon: "/platform/neard/logo192.png",
      loading: "/images/neard_loading.gif",
      welcomeImg: "/images/welcome_image/neard.png",
      errorImg: "/images/error_image/neard.png",
      scrollToBtmColor: "#e24847",
      btnBackgroundClass: "bg-theme-neard",
      textClass: "text-theme-neard",
      borderClass: "border-theme-neard",
      msgBackgroundClass: "bg-msg-neard",
      showGroupIconInAvatar: false,
      switch: {
        onHandleColor: "#e24847",
        onColor: "#fcd0d6",
        offHandleColor: "#7f7f7f",
        offColor: "#eaeaea",
      },
    },
    settings: {
      hasMultipleProfiles: true,
      showOneAvatarInSingleChat: false,
      canSearchGroups: true,
    },
    urls: {
      base: env.nearDMessengerUrl,
      home: env.webClientUrl,
      contactUs: `${env.nearDWebsiteUrl}/#section_contactus`,
      privacy: `${env.nearDWebsiteUrl}/privacy`,
      terms: `${env.nearDWebsiteUrl}/terms`,
      cookiesDomain: new URL(env.webClientUrl).hostname,
      loginUrl: buildLoginUrl().detail,
      logoutUrl: `${env.webClientUrl}/logout`,
    },
    translationKeys: {
      emptyRoomList: "im.neard-room-list-empty-text",
      createChatDesc: "im.create-chat-desc",
    },
    tracking: {
      ga: env.nearDGA,
    },
  },
};

export default settings;
