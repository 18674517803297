import { all, fork } from 'typed-redux-saga';
import AuthWatchers from "./auth/watchers";
import ChatroomWatchers from "./chatroom/watchers";
import GroupWatchers from "./group/watchers";
import MeWatchers from "./me/watchers";
import MessageWatchers from "./message/watchers";
import ProfileWatchers from "./profile/watchers";
import SettingWatchers from "./now/watchers";
import ResourceWatchers from "./resource/watchers";
import NotificationWatchers from "./notification/watchers";
import StickerWatchers from "./sticker/watchers";

export default function* root() {
  yield* all(
    [
      ...AuthWatchers,
      ...ChatroomWatchers,
      ...MeWatchers,
      ...GroupWatchers,
      ...MessageWatchers,
      ...SettingWatchers,
      ...ProfileWatchers,
      ...ResourceWatchers,
      ...NotificationWatchers,
      ...StickerWatchers,
    ].map(fork)
  );
}
