import { createReducer } from "@reduxjs/toolkit";
import _keyBy from "lodash/keyBy";
import _difference from "lodash/difference";
import { logout } from "../auth/actions";
import {
  removeStickerSetBookmarks,
  storeStickerBookmarks,
  storeStickerHistories,
  storeStickerIdsBySet,
  storeStickers,
  storeStickerSetBookmarks,
  storeStickerSets,
} from "./actions";
import StickerState from "./state";

const initialState: StickerState = {
  stickers: {},
  sets: {},
  bookmarks: {
    stickerIds: undefined,
    setIds: undefined,
  },
  setStickerIds: {},
  history: {
    stickerIds: undefined,
  },
};

const stickerReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(storeStickers, (state, { payload }) => {
      state.stickers = {
        ...state.stickers,
        ..._keyBy(payload.stickers, (sticker) => sticker.stickerId),
      };
    })
    .addCase(storeStickerSets, (state, { payload }) => {
      state.sets = {
        ...state.sets,
        ..._keyBy(payload.sets, (set) => set.stickerSetId),
      };
    })
    .addCase(storeStickerHistories, (state, { payload }) => {
      state.history.stickerIds = Array.from(
        new Set([...payload.stickerIds, ...(state.history.stickerIds ?? [])])
      );
    })
    .addCase(storeStickerBookmarks, (state, { payload }) => {
      state.bookmarks.stickerIds = [
        ...(state.bookmarks.stickerIds ?? []),
        ...payload.stickerIds,
      ];
    })
    .addCase(storeStickerSetBookmarks, (state, { payload }) => {
      state.bookmarks.setIds = Array.from(
        new Set([...(state.bookmarks.setIds ?? []), ...payload.setIds])
      );
    })
    .addCase(removeStickerSetBookmarks, (state, { payload }) => {
      state.bookmarks.setIds = _difference(
        state.bookmarks.setIds ?? [],
        payload.setIds
      );
    })
    .addCase(storeStickerIdsBySet, (state, { payload }) => {
      state.setStickerIds[payload.setId] = payload.stickerIds;
    })
    .addCase(logout, () => initialState)
);

export default stickerReducer;
