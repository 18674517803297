import { delRoom, quitRoom } from "modules/chatroom/actions";
import { roomSelector } from "modules/chatroom/selectors";
import { isGroupChat } from "modules/chatroom/utils";
import { fetchMySummary } from "modules/me/watchers";
import { showErrorMsg } from "modules/modal/actions";
import { AppState } from "modules/rootReducer";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeEvery } from 'typed-redux-saga';
import getImClient from "utils/imClient";

export default function* watchQuitRoom() {
  yield* takeEvery(quitRoom, function* (action): SagaIterator {
    try {
      const room = yield* select((state: AppState) =>
        roomSelector(state, { roomId: action.payload.roomId })
      );
      if (room?.isTemp === false) {
        yield* call(getImClient().chatroom.quit, action.payload.roomId);
        if (isGroupChat(room)) {
          yield* call(fetchMySummary);
        }
      } else {
        yield* put(delRoom(action.payload.roomId));
      }
    } catch (error) {
      yield* put(showErrorMsg());
      console.error(error);
    }
  });
}
