/// <reference types="@welldone-software/why-did-you-render" />
import "./wdyr";
import "reflect-metadata";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./i18n";
import { makeStore } from "modules/store";
import "./index.css";
import "styles/fontello/css/fontello.css";

ReactDOM.render(
  <Provider store={makeStore()}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
