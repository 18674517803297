import getImClient from "utils/imClient";
import { watchChannel } from "utils/saga-utils";
import createChatroomChannel from "../channel";
import watchCreateUserRoom from "./watchCreateUserRoom";
import watchDelRoom from "./watchDelRoom";
import watchMuteMember from "./watchMuteMember";
import watchPinMessage from "./watchPinMessage";
import watchPinRoom from "./watchPinRoom";
import watchQuitRoom from "./watchQuitRoom";
import watchStoreProfiles from "./watchStoreProfiles";
import watchUnMuteMember from "./watchUnMuteMember";
import watchUnpinMessage from "./watchUnpinMessage";
import watchUnpinRoom from "./watchUnpinRoom";
import watchUpdateMemberTyping from "./watchUpdateMemberTyping";
import watchUpdateRoomMuteSetting from "./watchUpdateRoomMuteSetting";
import watchUpsertRooms from "./watchUpsertRooms";

const chatroomWatchers = [
  watchChannel(createChatroomChannel(getImClient())),
  watchUpsertRooms,
  watchCreateUserRoom,
  watchQuitRoom,
  watchDelRoom,
  watchUpdateMemberTyping,
  watchStoreProfiles,
  watchUpdateRoomMuteSetting,
  watchPinMessage,
  watchUnpinMessage,
  watchPinRoom,
  watchUnpinRoom,
  watchMuteMember,
  watchUnMuteMember,
];

export default chatroomWatchers;
