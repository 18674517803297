import {
  FetchProfileEndpoint,
  FetchProfileRequest,
  FollowProfileEndpoint,
  FollowProfileRequest,
  UnfollowProfileEndpoint,
  UnfollowProfileRequest,
} from "@neard-packages/api-types/profile/endpoint";
import getImClient from "utils/imClient";
import {
  SearchProfilesEndpoint,
  SearchProfilesRequest,
} from "@neard-packages/api-types/search/endpoint/search-profiles";

export async function fetchProfile(request: FetchProfileRequest) {
  return getImClient().api.main().send(new FetchProfileEndpoint(request));
}

export async function followProfile(request: FollowProfileRequest) {
  return getImClient().api.main().send(new FollowProfileEndpoint(request));
}

export async function unfollowProfile(request: UnfollowProfileRequest) {
  return getImClient().api.main().send(new UnfollowProfileEndpoint(request));
}

export function searchProfiles(request: SearchProfilesRequest) {
  return getImClient().api.main().send(new SearchProfilesEndpoint(request));
}
