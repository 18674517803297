import { FirestoreProfile } from "@nearD/im-js-sdk/lib/im/models";
import { createAction } from "@reduxjs/toolkit";
import { ProfileStatusVM, ProfileVM } from "./view-models";

export const addProfile = createAction(
  "profile/AddProfile",
  (profileId: number) => ({
    payload: { profileId },
  })
);

export const updateProfile = createAction(
  "profile/UpdateProfile",
  (profileId: number, profile: FirestoreProfile) => ({
    payload: { profileId, profile },
  })
);

export const storeProfile = (profile: ProfileVM) => storeProfiles([profile]);

export const storeProfiles = createAction(
  "profile/StoreProfiles",
  (profiles: ProfileVM[]) => ({
    payload: { profiles },
  })
);

export const storeProfileStatuses = createAction(
  "profile/StoreProfileStatuses",
  (profileStatuses: ProfileStatusVM[]) => ({
    payload: { profileStatuses },
  })
);

export const storeProfileStatus = (status: ProfileStatusVM) =>
  storeProfileStatuses([status]);

export const checkProfileExists = createAction(
  "profile/checkProfileExists",
  (profileId: number) => ({
    payload: {
      profileId,
    },
  })
);

export const storeProfileExistence = createAction(
  "profile/storeProfileExistence",
  (profileId: number, isExist: boolean) => ({
    payload: {
      profileId,
      isExist,
    },
  })
);

export const followProfile = createAction(
  "profile/followProfile",
  (myProfileId: number, targetProfileId: number) => ({
    payload: {
      myProfileId,
      targetProfileId,
    },
  })
);

export const unfollowProfile = createAction(
  "profile/unfollowProfile",
  (profileId: number) => ({
    payload: {
      profileId,
    },
  })
);
