import { Sticker, StickerSet } from "@neard-packages/api-types/sticker/model";
import { createAction } from "@reduxjs/toolkit";

export const storeStickers = createAction(
  "sticker/StoreStickers",
  (stickers: Sticker[]) => ({
    payload: { stickers },
  })
);

export const storeStickerSets = createAction(
  "sticker/StoreStickerSets",
  (sets: StickerSet[]) => ({
    payload: { sets },
  })
);

export const storeStickerHistories = createAction(
  "sticker/StoreStickerHistories",
  (stickerIds: string[]) => ({
    payload: { stickerIds },
  })
);

export const storeStickerBookmarks = createAction(
  "sticker/StoreStickerBookmarks",
  (stickerIds: string[]) => ({
    payload: { stickerIds },
  })
);

export const storeStickerSetBookmarks = createAction(
  "sticker/StoreStickerSetBookmarks",
  (setIds: string[]) => ({
    payload: { setIds },
  })
);

export const removeStickerSetBookmarks = createAction(
  "sticker/removeStickerSetBookmarks",
  (setIds: string[]) => ({
    payload: { setIds },
  })
);

export const storeStickerIdsBySet = createAction(
  "sticker/StoreStickerIdsBySet",
  (setId: string, stickerIds: string[]) => ({
    payload: { setId, stickerIds },
  })
);

export const addStickerSet = createAction(
  "sticker/AddStickerSet",
  (setId: string) => ({
    payload: { setId },
  })
);

export const addStickerSetBookmark = createAction(
  "sticker/AddStickerSetBookmark",
  (setId: string) => ({
    payload: { setId },
  })
);

export const delStickerSetBookmark = createAction(
  "sticker/DelStickerSetBookmark",
  (setId: string) => ({
    payload: { setId },
  })
);
