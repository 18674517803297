import type { FeedResponse } from "@neard-packages/api-types/feed/response";
import { createAction } from "@reduxjs/toolkit";
import type {
  GroupResponse,
  GroupMemberResponse,
} from "@neard-packages/api-types/group/response";
import type { LocationResponse } from "@neard-packages/api-types/location/response";
import type { ProfileResponse } from "@neard-packages/api-types/profile/response";
import type { UrlMetaResponse } from "@neard-packages/api-types/urlmeta/response";
import { GroupPreferences } from "@neard-packages/api-types/group/model";
import { GroupChatRoom } from "@neard-packages/api-types/chatroom/model";

export const addFeedResource = createAction(
  "resource/AddFeedResource",
  (feedId: number) => ({
    payload: { feedId },
  })
);

export const addGroupResource = createAction(
  "resource/AddGroupResource",
  (groupId: number | string) => ({
    payload: { groupId },
  })
);

export const addGroupPreferencesResource = createAction(
  "resource/AddGroupPreferencesResource",
  (groupId: number) => ({
    payload: { groupId },
  })
);

export const addGroupMembersResource = createAction(
  "resource/AddGroupMemberResource",
  (groupId: number) => ({
    payload: { groupId },
  })
);

export const addProfileResource = createAction(
  "resource/AddProfileResource",
  (profileId: number, replaceExisted: boolean = false) => ({
    payload: {
      profileId,
      replaceExisted,
    },
  })
);

export const addLocationResource = createAction(
  "resource/AddLocationResource",
  (locationId: number) => ({
    payload: {
      locationId,
    },
  })
);

export const addUrlMetaResource = createAction(
  "resource/AddUrlMetaResource",
  (url: string) => ({ payload: { url } })
);

export const storeFeedResource = createAction(
  "resource/StoreFeedResource",
  (response: FeedResponse) => ({
    payload: { response },
  })
);

export const storeGroupResource = createAction(
  "resource/StoreGroupResource",
  (response: GroupResponse) => ({
    payload: { response },
  })
);

export const storeInvalidGroup = createAction(
  "resource/StoreInvalidGroup",
  (groupId: string | number) => ({ payload: { groupId } })
);

export const storeProfileResource = createAction(
  "resource/StoreProfileResource",
  (response: ProfileResponse) => ({
    payload: { response },
  })
);

export const storeLocationResource = createAction(
  "resource/StoreLocationResource",
  (response: LocationResponse) => ({
    payload: {
      response,
    },
  })
);

export const storeGroupMemberResource = createAction(
  "resource/StoreGroupMemberResource",
  (groupId: number, response: GroupMemberResponse) => ({
    payload: { groupId, response },
  })
);

export const storeGroupPreferencesResource = createAction(
  "resource/StoreGroupPreferencesResource",
  (groupId: number, preferences: GroupPreferences) => ({
    payload: { groupId, preferences },
  })
);

export const storeUrlMetaResource = createAction(
  "resource/StoreUrlMetaResource",
  (url: string, response: UrlMetaResponse | null) => ({
    payload: { response, url },
  })
);

export const storeChatroomResource = createAction(
  "resource/StoreChatroomResource",
  (chatroom: GroupChatRoom) => ({
    payload: { chatroom },
  })
);
