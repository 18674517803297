import { ImClient } from "@nearD/im-js-sdk";
import { eventChannel } from "redux-saga";
import { ImEventTypeNames } from "@nearD/im-js-sdk/lib/events/im.events";
import { updateProfile } from "./actions";

const createProfileChannel = (imClient: ImClient) =>
  eventChannel((emit) => {
    imClient.on(ImEventTypeNames.ProfileUpdated, (profileId, profile) => {
      emit(updateProfile(profileId, profile));
    });
    return () => {};
  });

export default createProfileChannel;
