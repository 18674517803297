import { platformUrlsSelector } from "modules/platform/selectors/platformUrlsSelector";
import { platformTypeSelector } from "modules/platform/selectors/platformTypeSelector";
import { SagaIterator } from "redux-saga";
import { put, select, takeEvery } from "typed-redux-saga";
import { loginFail, storeIsLogged } from "../actions";

export default function* watchLoginFail() {
  yield* takeEvery(loginFail, function* (): SagaIterator {
    yield* put(storeIsLogged(false));
    const platform = yield* select(platformTypeSelector);
    const urls = yield* select(platformUrlsSelector);
    const url = new URL(urls.loginUrl);
    if (platform === "nearD") {
      url.searchParams.set("redirect_uri", window.location.href);
    }
    window.location.href = url.toString();
  });
}
