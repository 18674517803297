import { createReducer } from "@reduxjs/toolkit";
import { logout } from "../auth/actions";
import { storeRemoteConfig } from "./actions";
import ConfigState from "./state";

const initialState: ConfigState = {
  remoteConfigs: null,
};

const configReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(storeRemoteConfig, (state, action) => {
      state.remoteConfigs = action.payload.config;
    })
    .addCase(logout, () => initialState)
);

export default configReducer;
