import { takeLeading } from 'typed-redux-saga';
import { sendTextMsg, sendTextMsgs } from "../actions";
import sendTextMsgHandler from "../sendMsgHandler/sendTextMsgHandler";

export default function* watchSendTextMsgs() {
  yield* takeLeading(sendTextMsgs, function* ({ payload: { messages } }) {
    for (const msg of messages) {
      // build action payload
      const action = sendTextMsg(msg.roomId, msg.plainText, msg.entityRanges);
      yield sendTextMsgHandler.sendMsg(action);
    }
  });
}
