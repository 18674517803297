import { createAction } from "@reduxjs/toolkit";
import { ChatroomType } from "@nearD/im-js-sdk/lib/api/chatroom/enum/chatroom-type.enum";

export const storeRoomQueryFilter = createAction(
  "now/StoreRoomQueryFilter",
  (queryString: string) => ({
    payload: { queryString },
  })
);

export const storeRoomProfilesFilter = createAction(
  "now/StoreRoomProfilesFilter",
  (profileIds: Record<number, boolean>) => ({
    payload: { profileIds },
  })
);

export const setCurrentRoom = createAction(
  "now/SetCurrentRoom",
  (roomId: string | null) => ({
    payload: { roomId },
  })
);

export const storeCurrentRoom = createAction(
  "now/StoreCurrentRoom",
  (roomId: string | null) => ({
    payload: { roomId },
  })
);

export const imClientInited = createAction("now/ImClientInited");

export const storeAnchorToBottom = createAction(
  "now/StoreAnchorToBottom",
  (anchorToBottom: boolean) => ({
    payload: { anchorToBottom },
  })
);

export const storeIsLoadingMsgs = createAction(
  "now/StoreIsLoadingMsgs",
  (isLoading: boolean) => ({
    payload: { isLoading },
  })
);

export const storeIsAllMsgsLoaded = createAction(
  "now/StoreIsAllMsgsLoaded",
  (isLoaded: boolean) => ({
    payload: { isLoaded },
  })
);

export const startPlayingMsg = createAction(
  "now/StartPlayingMsg",
  (msgId: string) => ({
    payload: { msgId },
  })
);

export const stopPlayingMsg = createAction(
  "now/StopPlayingMsg",
  (msgId: string, isFinished: boolean) => ({
    payload: { msgId, isFinished },
  })
);

export const updateUI = createAction("now/updateUI", (key: string) => ({
  payload: { key },
}));

export const typing = createAction("now/Typing");

export const storeRoomTypesFilter = createAction(
  "now/StoreRoomTypesFilter",
  (type: ChatroomType | null) => ({
    payload: { type },
  })
);

export const storeIsRoomQueryOnFocus = createAction(
  "now/StoreIsRoomQueryOnFocus",
  (isFocused: boolean) => ({ payload: { isFocused } })
);
