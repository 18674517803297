import { useTranslation } from "react-i18next";
import Toast from ".";

export default function SentJoinGroupRequestToast() {
  const { t } = useTranslation("groups");
  return (
    <Toast
      iconClass="icon-joined_group"
      text={t("groups.toast-sent-member-request")}
    />
  );
}
