import type { BlockProfileRequest } from "@neard-packages/api-types/me/endpoint/block-profile";
import { IEntityRange } from "@nearD/im-js-sdk/lib/api/chatroom/models/entity-range.entity";
import { hide, show } from "@nearD/redux-modal";
import type { MediaPreview } from "components/Chatroom/InputToolbar/types";
import type { ProfileVM } from "modules/profile/view-models";
import {
  MODAL_ASK_REPLACE_URL_MODAL,
  MODAL_CANCEL_MEMBER_REQUEST,
  MODAL_CONFIRM_BLOCK_PROFILE,
  MODAL_CONFIRM_QUIT_GROUP,
  MODAL_CONFIRM_QUIT_ROOM,
  MODAL_CREATE_CHAT,
  MODAL_ERROR,
  MODAL_FOLLOW_PROFILE,
  MODAL_FORWARD_MSG,
  MODAL_FORWARD_MSG_TO_GROUP,
  MODAL_FORWARD_MSG_TO_PROFILE,
  MODAL_FORWARD_MSG_TO_PROFILE_WITH_SENDER,
  MODAL_GROUP_INFO,
  MODAL_IMAGES,
  MODAL_JOIN_GROUP,
  MODAL_MANAGE_STICKER_SETS,
  MODAL_MEDIA_PREVIEW,
  MODAL_MSG_MENU,
  MODAL_MUTE_MEMBER,
  MODAL_NOTIFICATION_GUIDELINE,
  MODAL_PINNED_MSG,
  MODAL_PIN_MSG,
  MODAL_PRIVATE_GROUP,
  MODAL_PROFILE_INFO,
  MODAL_SETTINGS,
  MODAL_UNFOLLOW_PROFILE,
  MODAL_VIDEO,
} from "./types";

export const showProfileInfoModal = (profileId: number, roomId: string) =>
  show(MODAL_PROFILE_INFO, { profileId, roomId });
export const showGroupInfoModal = (groupId: number, roomId: string) =>
  show(MODAL_GROUP_INFO, { groupId, roomId });
export const showCreateChatModal = () => show(MODAL_CREATE_CHAT);
export const showSettingsModal = () => show(MODAL_SETTINGS);
export const showConfirmQuitRoomModal = (id: string) =>
  show(MODAL_CONFIRM_QUIT_ROOM, { id });
export const showImagesModal = (images: string[], initIndex: number = 0) =>
  show(MODAL_IMAGES, { images, initIndex });
export const showVideoModal = (
  videoSrc: string,
  width: number,
  height: number
) => show(MODAL_VIDEO, { videoSrc, width, height });
export const showConfirmQuitGroupModal = (id: number) =>
  show(MODAL_CONFIRM_QUIT_GROUP, { id });
export const showCancelMemberRequestModal = (
  groupId: number,
  requestId: number
) => show(MODAL_CANCEL_MEMBER_REQUEST, { groupId, requestId });

export const hideCreateChatModal = () => hide(MODAL_CREATE_CHAT);
export const showErrorMsg = (
  title?: string,
  content?: string,
  confirmText?: string
) => show(MODAL_ERROR, { title, content, confirmText });
export const showNotificationGuidelineModal = () =>
  show(MODAL_NOTIFICATION_GUIDELINE);
export const hideNotificationGuidelineModal = () =>
  hide(MODAL_NOTIFICATION_GUIDELINE);
export const showJoinGroupModal = (groupId: number) =>
  show(MODAL_JOIN_GROUP, { groupId });
export const showManageStickerSetsModal = () => show(MODAL_MANAGE_STICKER_SETS);
export const showMsgMenuModal = (msgId: string) =>
  show(MODAL_MSG_MENU, { msgId });
export const showPinnedMsgModal = (roomId: string, msgId: string) =>
  show(MODAL_PINNED_MSG, { roomId, msgId });
export const showUnpinMsgModal = (roomId: string, msgId: string) =>
  show(MODAL_PIN_MSG, { roomId, msgId, isPin: false });
export const showPinMsgModal = (roomId: string, msgId: string) =>
  show(MODAL_PIN_MSG, { roomId, msgId, isPin: true });
export const showMuteMemberModal = (roomId: string, targetProfileId: number) =>
  show(MODAL_MUTE_MEMBER, { roomId, targetProfileId });
export const showFollowProfileModal = (targetProfileId: number) =>
  show(MODAL_FOLLOW_PROFILE, { targetProfileId });
export const showUnfollowProfileModal = (targetProfileId: number) =>
  show(MODAL_UNFOLLOW_PROFILE, { targetProfileId });

export const showForwardMsgModal = (
  msgId: string,
  sourceRoomId: string,
  sourceGroupId: number
) => show(MODAL_FORWARD_MSG, { sourceRoomId, sourceGroupId, msgId });

export const showForwardMsgToGroupModal = (
  msgId: string,
  sourceRoomId: string,
  sourceGroupId: number
) => show(MODAL_FORWARD_MSG_TO_GROUP, { sourceRoomId, sourceGroupId, msgId });

export const showForwardMsgToProfileModal = (
  msgId: string,
  sourceRoomId: string,
  sourceGroupId: number,
  initialSelectedProfile?: ProfileVM
) =>
  show(MODAL_FORWARD_MSG_TO_PROFILE, {
    sourceRoomId,
    sourceGroupId,
    msgId,
    initialSelectedProfile,
  });

export const showForwardMsgToProfileWithSenderModal = (
  msgId: string,
  sourceRoomId: string,
  sourceGroupId: number,
  targetProfile: ProfileVM,
  defaultSenderProfileId: number
) =>
  show(MODAL_FORWARD_MSG_TO_PROFILE_WITH_SENDER, {
    sourceRoomId,
    sourceGroupId,
    msgId,
    targetProfile,
    defaultSenderProfileId,
  });

export const showAskReplaceUrlModal = (
  roomId: string,
  plainText: string,
  entityRanges: IEntityRange[],
  involvedProfileIds: number[]
) =>
  show(MODAL_ASK_REPLACE_URL_MODAL, {
    roomId,
    plainText,
    entityRanges,
    involvedProfileIds,
  });

export const showMediaPreviewModal = (roomId: string, files: MediaPreview[]) =>
  show(MODAL_MEDIA_PREVIEW, { roomId, files });

export const showBlockProfileModal = (payload: BlockProfileRequest) =>
  show(MODAL_CONFIRM_BLOCK_PROFILE, { profileId: payload.profileId });

export const showPrivateGroupModal = (url: string) =>
  show(MODAL_PRIVATE_GROUP, { url });
