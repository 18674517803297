import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../rootReducer";

const getState = (state: AppState) => state.profile;
export const profilesSelector = createSelector(
  [getState],
  (state) => state.profiles
);
const selectProfileThirdParty = createSelector(
  [getState],
  (state) => state.thirdParties
);
const selectProfileExistence = createSelector(
  [getState],
  (state) => state.existence
);
export const getProfileId = (state: AppState, props: { profileId: number }) =>
  props.profileId;
export const myDefaultProfileIdSelector = (state: AppState) =>
  state.me.userInfo?.defaultProfileId;

export const profileSelector = createSelector(
  [profilesSelector, getProfileId],
  (profiles, profileId) => profiles[profileId]
);

export const profileThirdPartySelector = createSelector(
  [selectProfileThirdParty, getProfileId],
  (thirdParty, profileId) => thirdParty?.[profileId]
);

export const isProfileExistSelector = createSelector(
  [selectProfileExistence, getProfileId],
  (existence, profileId) => existence[profileId] as boolean | undefined
);
