import { isGroupChat } from "modules/chatroom/utils";
import { addGroupResource } from "modules/resource/actions";
import ReactGA from "react-ga";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeEvery } from 'typed-redux-saga';
import getImClient from "utils/imClient";
import resetRoomUnreadCount from "../../chatroom/sagas/resetRoomUnreadCount";
import {
  isRoomListInitedSelector,
  roomSelector
} from "../../chatroom/selectors";
import { clearOldMsgs, fetchRoomPinnedMsgs } from "../../message/actions";
import { AppState } from "../../rootReducer";
import {
  setCurrentRoom,
  stopPlayingMsg,
  storeAnchorToBottom,
  storeCurrentRoom,
  storeIsAllMsgsLoaded,
  storeIsLoadingMsgs
} from "../actions";
import { currentRoomIdSelector, playingMsgIdSelector } from "../selectors";

export default function* watchSetCurrentRoom() {
  yield* takeEvery(setCurrentRoom, function* (action): SagaIterator {
    const { roomId } = action.payload;
    const isRoomListInited = yield* select(isRoomListInitedSelector);
    const currentRoomId = yield* select(currentRoomIdSelector);
    const playingMsgId = yield* select(playingMsgIdSelector);
    let isNewRoom = false;
    if (roomId) {
      const targetRoom = yield* select((state: AppState) =>
        roomSelector(state, { roomId })
      );
      isNewRoom = isRoomListInited && targetRoom?.isTemp === true;
      if (!isNewRoom) {
        try {
          yield* call(getImClient().listenRoomDetail, roomId);
          yield* call(resetRoomUnreadCount, roomId);
          yield* put(fetchRoomPinnedMsgs(roomId));
          if (targetRoom && isGroupChat(targetRoom)) {
            yield* put(addGroupResource(targetRoom.groupId));
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    if (currentRoomId !== roomId) {
      ReactGA.pageview(`/${roomId ?? ""}`);
    }
    if (currentRoomId) {
      yield* put(clearOldMsgs([currentRoomId]));
    }
    yield* put(storeCurrentRoom(roomId));
    yield* put(storeIsAllMsgsLoaded(isNewRoom));
    yield* put(storeIsLoadingMsgs(false));
    yield* put(storeAnchorToBottom(true));
    if (playingMsgId !== null) {
      yield* put(stopPlayingMsg(playingMsgId, false));
    }
  });
}
