import { BaseFetchPaginatedRequest } from "@neard-packages/api-types/general";
import {
  FetchMyProfilesEndpoint,
  FetchMySummaryEndpoint,
  FetchMyInfoEndpoint,
  BlockProfileRequest,
  BlockProfileEndpoint,
  UnblockProfileRequest,
  UnblockProfileEndpoint,
  FetchMyGroupInvitationsRequest,
  FetchMyGroupInvitationsEndpoint,
  UpdateMyPreferencesEndpoint,
  UpdateMyPreferencesRequest,
} from "@neard-packages/api-types/me/endpoint";
import getImClient from "utils/imClient";

export async function getProfiles(request: BaseFetchPaginatedRequest) {
  return getImClient().api.main().send(new FetchMyProfilesEndpoint(request));
}

export async function getSummary() {
  return getImClient().api.main().send(new FetchMySummaryEndpoint());
}

export async function getUserInfo() {
  return getImClient().api.main().send(new FetchMyInfoEndpoint());
}

export async function blockProfile(request: BlockProfileRequest) {
  return getImClient().api.main().send(new BlockProfileEndpoint(request));
}

export async function unblockProfile(request: UnblockProfileRequest) {
  return getImClient().api.main().send(new UnblockProfileEndpoint(request));
}

export async function getMyGroupInvitations(
  request: FetchMyGroupInvitationsRequest
) {
  return getImClient()
    .api.main()
    .send(new FetchMyGroupInvitationsEndpoint(request));
}

export async function updateSettings(request: UpdateMyPreferencesRequest) {
  return getImClient()
    .api.main()
    .send(new UpdateMyPreferencesEndpoint(request));
}
