import { createAction } from "@reduxjs/toolkit";

export const quitGroup = createAction(
  "group/QuitGroup",
  (groupId: number, profileId: number) => ({
    payload: {
      groupId,
      profileId,
    },
  })
);

export const joinGroup = createAction(
  "group/JoinGroup",
  (groupId: number, profileId: number) => ({
    payload: {
      groupId,
      profileId,
    },
  })
);

export const cancelRequest = createAction(
  "group/CancelRequest",
  (groupId: number, requestId: number) => ({
    payload: {
      groupId,
      requestId,
    },
  })
);
