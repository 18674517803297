import { ImClient } from "@nearD/im-js-sdk";
import { ImEventTypeNames } from "@nearD/im-js-sdk/lib/events/im.events";
import { eventChannel } from "redux-saga";
import {
  storeUnreadCounts,
  delRoom,
  delRoomMember,
  storeRoomMembers,
  storeRoomSetting,
  updateMemberTyping,
  upsertRooms,
  updatePinnedRooms,
} from "./actions";

function createChatroomChannel(imClient: ImClient) {
  return eventChannel((emit) => {
    imClient.on(ImEventTypeNames.RoomCreatedInBatch, (roomDataList) => {
      emit(upsertRooms(roomDataList.map((roomData) => roomData.chatroom)));
    });
    imClient.on(ImEventTypeNames.RoomUpdatedInBatch, (roomDataList) => {
      emit(upsertRooms(roomDataList.map((roomData) => roomData.chatroom)));
    });
    imClient.on(ImEventTypeNames.RoomUpdated, (_, room) => {
      emit(upsertRooms([room]));
    });
    imClient.on(ImEventTypeNames.RoomMemberCreatedInBatch, (members) => {
      //assumed roomId in every batch is the same
      const roomId = members[0].roomId;
      emit(
        storeRoomMembers(
          roomId,
          members.map(({ member }) => member)
        )
      );
    });
    imClient.on(ImEventTypeNames.RoomMemberRemoved, (roomId, member) => {
      if (member.profileId === "all") {
        return;
      }
      emit(delRoomMember(roomId, member.profileId));
    });
    imClient.on(ImEventTypeNames.RoomMemberTypeUpdated, (roomId, member) => {
      emit(storeRoomMembers(roomId, [member]));
    });
    imClient.on(ImEventTypeNames.RoomDeleted, (roomId) => {
      emit(delRoom(roomId));
    });
    imClient.on(ImEventTypeNames.RoomSettingUpdated, (setting) => {
      emit(storeRoomSetting(setting.id, setting.muted));
    });
    imClient.on(ImEventTypeNames.RoomSoftDeleted, (roomId) => {
      emit(delRoom(roomId));
    });
    imClient.on(ImEventTypeNames.UnreadCountUpdatedInBatch, (unreadCounts) => {
      emit(storeUnreadCounts(unreadCounts));
    });
    imClient.on(ImEventTypeNames.RoomTyping, (roomId, sender) => {
      emit(updateMemberTyping(roomId, sender, "add"));
    });
    imClient.on(ImEventTypeNames.RoomStopTyping, (roomId, sender) => {
      emit(updateMemberTyping(roomId, sender, "del"));
    });
    imClient.on(ImEventTypeNames.PinnedRoomsUpdated, (roomIds: string[]) => {
      emit(updatePinnedRooms(roomIds));
    });
    return () => {};
  });
}

export default createChatroomChannel;
