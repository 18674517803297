import { all, call, put } from 'typed-redux-saga';
import getImClient from "utils/imClient";
import { storeUnreadCounts } from "../actions";

export default function* resetRoomUnreadCount(roomId: string) {
  try {
    yield* all([
      put(storeUnreadCounts([{ roomId, unreadCount: 0 }])),
      call(getImClient().chatroom.markAsRead, roomId),
    ]);
  } catch (error) {
    console.error(error);
  }
}
