import { SagaIterator } from "redux-saga";
import { all, put, takeEvery } from "redux-saga/effects";
import { call } from "typed-redux-saga";
import { getRemoteConfig } from "../config/sagas";
import { imClientInited } from "../now/actions";
import * as actions from "./actions";
import * as api from "./api";

function* watchInitUserRecords() {
  yield takeEvery(imClientInited, function* () {
    yield initBookmarkedSets();
    yield initStickerBookmarks();
    yield initStickerHistories();
  });
}

function* watchAddStickerSet() {
  yield takeEvery(actions.addStickerSet, function* (action): SagaIterator {
    try {
      const response = yield* call(api.fetchStickerSet, action.payload);
      const { stickerSet } = response.data;
      yield put(actions.storeStickerSets([stickerSet]));
      yield put(actions.storeStickers(stickerSet.stickers));
      yield put(
        actions.storeStickerIdsBySet(
          stickerSet.stickerSetId,
          stickerSet.stickers.map((sticker) => sticker.stickerId)
        )
      );
    } catch (error) {
      console.error(error);
    }
  });
}

function* initBookmarkedSets(): SagaIterator {
  const remoteConfig = yield* call(getRemoteConfig);
  const defaultStickerSetIds: string[] = JSON.parse(
    remoteConfig?.["nd_chatroom_sticker_default_set_ids"] ?? "[]"
  ).map((setId: number) => `${setId}`);
  yield all(
    defaultStickerSetIds.map((setId) => put(actions.addStickerSet(setId)))
  );
  const response = yield* call(api.fetchStickerSetBookmarks);
  const bookmarkedSets = response.data.stickerSets;
  yield put(actions.storeStickerSets(bookmarkedSets));
  const setIds = bookmarkedSets.map((set) => set.stickerSetId);
  yield put(
    actions.storeStickerSetBookmarks([...defaultStickerSetIds, ...setIds])
  );
}

function* initStickerBookmarks() {
  const response = yield* call(api.fetchStickerBookmarks);
  const stickers = response.data.stickers;
  yield put(actions.storeStickers(stickers));
  const stickerIds = stickers.map((sticker) => sticker.stickerId);
  yield put(actions.storeStickerBookmarks(stickerIds));
}

function* initStickerHistories() {
  const response = yield* call(api.fetchStickerHistories);
  const { stickers } = response.data;
  yield put(actions.storeStickers(stickers));
  const stickerIds = stickers.map((sticker) => sticker.stickerId);
  yield put(actions.storeStickerHistories(stickerIds));
}

function* watchAddStickerSetBookmark() {
  yield takeEvery(
    actions.addStickerSetBookmark,
    function* ({ payload }): SagaIterator {
      const { setId } = payload;
      const response = yield* call(api.insertStickerSetBookmark, payload);
      const stickerSet = response.data.stickerSet;
      yield all([
        put(actions.storeStickerSets([stickerSet])),
        put(actions.storeStickerSetBookmarks([setId])),
      ]);
    }
  );
}

function* watchDelStickerSetBookmark() {
  yield takeEvery(
    actions.delStickerSetBookmark,
    function* ({ payload }): SagaIterator {
      const { setId } = payload;
      yield* call(api.deleteStickerSetBookmark, payload);
      yield put(actions.removeStickerSetBookmarks([setId]));
    }
  );
}

const stickerWatchers = [
  watchInitUserRecords,
  watchAddStickerSet,
  watchAddStickerSetBookmark,
  watchDelStickerSetBookmark,
];

export default stickerWatchers;
