import _throttle from "lodash/throttle";
import { roomSelector } from "modules/chatroom/selectors";
import { myProfileSummarySelector } from "modules/me/selectors";
import { isSystemMsg } from "modules/message/utils";
import { AppState } from "modules/rootReducer";
import { SagaIterator } from "redux-saga";
import { put, select, takeEvery } from "typed-redux-saga";
import { isDocOnFocus } from "utils/onFocus";
import { addUnnoticedCount, notifyMsg } from "../actions";
import {
  isNotifAllowedSelector,
  isNotifGrantedSelector,
  isNotifSoundAllowedSelector,
} from "../selectors";

const playNotifySound = _throttle(
  () => {
    new Audio("/audio/notification.mp3").play();
  },
  5000,
  { leading: true, trailing: false }
);

export default function* watchNotifyMsg() {
  yield* takeEvery(notifyMsg, function* ({ payload }): SagaIterator {
    const isAllowed = yield* select(isNotifAllowedSelector);
    if (!isAllowed) {
      return;
    }
    const { roomId, msg } = payload;
    const isGranted = yield* select(isNotifGrantedSelector);
    const room = yield* select((state: AppState) =>
      roomSelector(state, { roomId })
    );
    const myProfilesSummaryMap = yield* select(myProfileSummarySelector);
    if (
      msg.senderProfileId in (myProfilesSummaryMap ?? {}) ||
      isDocOnFocus() ||
      !isGranted ||
      isSystemMsg(msg.content) ||
      (room?.isMuted ?? true)
    ) {
      return;
    }
    const isSoundAllowed = yield* select(isNotifSoundAllowedSelector);
    if (isSoundAllowed) {
      playNotifySound();
    }
    yield* put(addUnnoticedCount());
  });
}
