import { createAction } from "@reduxjs/toolkit";

export const login = createAction(
  "auth/Login",
  (email: string, password: string) => ({
    payload: {
      email,
      password,
    },
  })
);

export const loginByToken = createAction(
  "auth/LoginByToken",
  (token: string, platform: "nearD" | "uwants") => ({
    payload: {
      token,
      platform,
    },
  })
);

export const loginSuccess = createAction("auth/LoginSuccess");
export const loginFail = createAction("auth/LoginFail");
export const logout = createAction("auth/Logout");

export const updateQRCode = createAction(
  "auth/UpdateQRCode",
  (val: string) => ({
    payload: {
      val,
    },
  })
);

export const expireQRCode = createAction("auth/ExpireQRCode");
export const initLogin = createAction("auth/InitLogin");

export const storeIsLogged = createAction(
  "auth/StoreIsLogged",
  (isLogged: boolean) => ({
    payload: {
      isLogged,
    },
  })
);
