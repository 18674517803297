import { createSelector } from "@reduxjs/toolkit";
import { getRoomId } from "../chatroom/selectors";
import { AppState } from "../rootReducer";
import { MessageVM } from "./view-models";

const getState = (state: AppState) => state.message;
const getMsgId = (state: AppState, { msgId }: { msgId: string }) => msgId;

export const msgIdsSelector = createSelector(
  [getState, getRoomId],
  (state, roomId) => {
    return state.orderedList[roomId] as string[] | undefined;
  }
);

export const lastMsgSelector = createSelector(
  [getState, getRoomId],
  (state, roomId) => {
    return state.lastMessage[roomId];
  }
);

export const msgSelector = createSelector(
  [getState, getRoomId, getMsgId],
  (msgState, roomId, msgId) => {
    return msgState.messages[roomId]?.[msgId];
  }
);

export const pinnedMsgSelector = createSelector(
  [getState, getRoomId, getMsgId],
  (msgState, roomId, msgId) => {
    return msgState.pinnedMessages[roomId]?.[msgId];
  }
);

export const previousMsgSelector = createSelector(
  [getState, getRoomId, getMsgId],
  (state, roomId, msgId) => {
    const msgList = state.orderedList;
    const currentMsgIndex = msgList[roomId].indexOf(msgId);
    const previousMsgId = msgList[roomId][currentMsgIndex + 1];
    return state.messages[roomId][previousMsgId] as MessageVM | undefined;
  }
);

export const nextMsgSelector = createSelector(
  [getState, getRoomId, getMsgId],
  (state, roomId, msgId) => {
    const msgList = state.orderedList;
    const currentMsgIndex = msgList[roomId].indexOf(msgId);
    if (currentMsgIndex === 0) {
      return undefined;
    }
    const nextMsgId = msgList[roomId][currentMsgIndex - 1];
    return state.messages[roomId][nextMsgId] as MessageVM | undefined;
  }
);

export const firstRoomMsgIdSelector = createSelector(
  [msgIdsSelector],
  (msgIds) => {
    return (msgIds ?? [])[0];
  }
);

export const tempMsgSelector = createSelector(
  [getState, getMsgId],
  (state, msgId) => {
    return state.pendingMessages[msgId];
  }
);

export const readCountSelector = createSelector([msgSelector], (msg) => {
  return msg.meta.readCount;
});

export const replyMsgSelector = createSelector(
  [getState, getRoomId],
  (state, roomId) => {
    return state.replyMessage[roomId] as MessageVM | undefined;
  }
);
