import { createReducer } from "@reduxjs/toolkit";
import settings from "./settings";
import { getPlatformTypeByUrl } from "./utils";

const type = getPlatformTypeByUrl(window.location.href);
const initialState = settings[type];

const platformReducer = createReducer(initialState, (builder) =>
  builder.addDefaultCase((state) => state)
);

export default platformReducer;
