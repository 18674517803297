import { combineReducers } from "@reduxjs/toolkit";
import { reducer as modalReducer } from "@nearD/redux-modal";
import platformReducer from "./platform/reducer";
import roomReducer from "./chatroom/reducer";
import profileReducer from "./profile/reducer";
import meReducer from "./me/reducer";
import nowReducer from "./now/reducer";
import messageReducer from "./message/reducer";
import authReducer from "./auth/reducer";
import resourceReducer from "./resource/reducer";
import notificationReducer from "./notification/reducer";
import stickerReducer from "./sticker/reducer";
import configReducer from "./config/reducer";
import { reducer as deviceReducer } from "@nearD/redux-device";

export const rootReducer = combineReducers({
  device: deviceReducer,
  chatroom: roomReducer,
  modal: modalReducer,
  platform: platformReducer,
  profile: profileReducer,
  me: meReducer,
  now: nowReducer,
  message: messageReducer,
  auth: authReducer,
  resource: resourceReducer,
  notification: notificationReducer,
  sticker: stickerReducer,
  config: configReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
