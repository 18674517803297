import { SagaIterator } from "redux-saga";
import { call, select, takeEvery } from "typed-redux-saga";
import { pinRoom } from "../actions";
import { updatePinnedRoomIds } from "../api";
import { pinnedRoomIdsSelector } from "../selectors";

export default function* watchPinRoom() {
  yield* takeEvery(pinRoom, function* ({ payload: { roomId } }): SagaIterator {
    try {
      const pinnedRoomIds = yield* select(pinnedRoomIdsSelector);
      yield* call(updatePinnedRoomIds, {
        pinnedChatroomIds: [roomId, ...pinnedRoomIds],
      });
    } catch (error) {
      console.error(error);
    }
  });
}
