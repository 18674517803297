export const MODAL_GROUP_INFO = "MODAL_GROUP_INFO";
export const MODAL_PROFILE_INFO = "MODAL_PROFILE_INFO";
export const MODAL_CREATE_CHAT = "MODAL_CREATE_CHAT";
export const MODAL_SETTINGS = "MODAL_SETTINGS";
export const MODAL_CONFIRM_QUIT_ROOM = "MODAL_CONFIRM_QUIT_ROOM";
export const MODAL_ERROR = "MODAL_ERROR";
export const MODAL_CONFIRM_BLOCK_PROFILE = "MODAL_CONFIRM_BLOCK_PROFILE";
export const MODAL_IMAGES = "MODAL_IMAGES";
export const MODAL_VIDEO = "MODAL_VIDEO";
export const MODAL_CONFIRM_QUIT_GROUP = "MODAL_CONFIRM_QUIT_GROUP";
export const MODAL_CANCEL_MEMBER_REQUEST = "MODAL_CANCEL_MEMBER_REQUEST";
export const MODAL_NOTIFICATION_GUIDELINE = "MODAL_NOTIFICATION_GUIDELINE";
export const MODAL_JOIN_GROUP = "MODAL_JOIN_GROUP";
export const MODAL_MANAGE_STICKER_SETS = "MODAL_MANAGE_STICKER_SETS";
export const MODAL_MSG_MENU = "MODAL_MSG_MENU";
export const MODAL_PINNED_MSG = "MODAL_PINNED_MSG";
export const MODAL_PIN_MSG = "MODAL_PIN_MSG";
export const MODAL_MUTE_MEMBER = "MODAL_MUTE_MEMBER";
export const MODAL_FOLLOW_PROFILE = "MODAL_FOLLOW_PROFILE";
export const MODAL_UNFOLLOW_PROFILE = "MODAL_UNFOLLOW_PROFILE";
export const MODAL_FORWARD_MSG = "MODAL_FORWARD_MSG";
export const MODAL_FORWARD_MSG_TO_GROUP = "MODAL_FORWARD_MSG_TO_GROUP";
export const MODAL_FORWARD_MSG_TO_PROFILE = "MODAL_FORWARD_MSG_TO_PROFILE";
export const MODAL_FORWARD_MSG_TO_PROFILE_WITH_SENDER =
  "MODAL_FORWARD_MSG_TO_PROFILE_WITH_SENDER";
export const MODAL_ASK_REPLACE_URL_MODAL = "MODAL_ASK_REPLACE_URL_MODAL";
export const MODAL_MEDIA_PREVIEW = "MODAL_MEDIA_PREVIEW";
export const MODAL_PRIVATE_GROUP = "MODAL_PRIVATE_GROUP";
