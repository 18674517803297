import { createReducer } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { logout } from "../auth/actions";
import {
  imClientInited,
  startPlayingMsg,
  stopPlayingMsg,
  storeAnchorToBottom,
  storeCurrentRoom,
  storeIsAllMsgsLoaded,
  storeIsLoadingMsgs,
  storeRoomProfilesFilter,
  storeRoomQueryFilter,
  storeRoomTypesFilter,
  updateUI,
  storeIsRoomQueryOnFocus,
} from "./actions";
import NowState from "./state";

const initialState: NowState = {
  roomProfilesFilter: {},
  roomQueryFilter: "",
  roomTypesFilter: null,
  currentRoomId: null,
  isImClientInited: false,
  isAnchorToBottom: true,
  isLoadingMessages: false,
  isAllMessagesLoaded: false,
  playingMsgId: null,
  uiLastUpdatedAt: dayjs().valueOf(),
  isRoomQueryOnFocus: false,
};

const nowReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(storeRoomQueryFilter, (state, action) => {
      state.roomQueryFilter = action.payload.queryString;
    })
    .addCase(storeRoomProfilesFilter, (state, action) => {
      state.roomProfilesFilter = action.payload.profileIds;
    })
    .addCase(storeCurrentRoom, (state, action) => {
      state.currentRoomId = action.payload.roomId;
    })
    .addCase(imClientInited, (state) => {
      state.isImClientInited = true;
    })
    .addCase(storeAnchorToBottom, (state, action) => {
      state.isAnchorToBottom = action.payload.anchorToBottom;
    })
    .addCase(storeIsLoadingMsgs, (state, action) => {
      state.isLoadingMessages = action.payload.isLoading;
    })
    .addCase(storeIsAllMsgsLoaded, (state, action) => {
      state.isAllMessagesLoaded = action.payload.isLoaded;
    })
    .addCase(startPlayingMsg, (state, action) => {
      state.playingMsgId = action.payload.msgId;
    })
    .addCase(stopPlayingMsg, (state, action) => {
      if (state.playingMsgId === action.payload.msgId) {
        state.playingMsgId = null;
      }
    })
    .addCase(updateUI, (state) => {
      state.uiLastUpdatedAt = dayjs().valueOf();
    })
    .addCase(storeRoomTypesFilter, (state, { payload }) => {
      state.roomTypesFilter = payload.type;
    })
    .addCase(storeIsRoomQueryOnFocus, (state, { payload: { isFocused } }) => {
      state.isRoomQueryOnFocus = isFocused;
    })
    .addCase(logout, () => initialState)
);

export default nowReducer;
