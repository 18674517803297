import {
  FetchStickerBookmarksEndpoint,
  FetchStickerHistoriesEndpoint,
  FetchStickerSetBookmarksEndpoint,
  FetchStickerSetEndpoint,
  FetchStickerSetRequest,
  FetchStickerSetsEndpoint,
  InsertStickerSetBookmarkEndpoint,
  InsertStickerSetBookmarkRequest,
  DeleteStickerSetBookmarkEndpoint,
  DeleteStickerSetBookmarkRequest,
} from "@neard-packages/api-types/sticker/endpoint";
import getImClient from "utils/imClient";

export function fetchStickerHistories() {
  return getImClient().api.main().send(new FetchStickerHistoriesEndpoint());
}

export function fetchStickerSets() {
  return getImClient().api.main().send(new FetchStickerSetsEndpoint());
}

export function fetchStickerSet(request: FetchStickerSetRequest) {
  return getImClient().api.main().send(new FetchStickerSetEndpoint(request));
}

export function fetchStickerBookmarks() {
  return getImClient().api.main().send(new FetchStickerBookmarksEndpoint());
}

export function fetchStickerSetBookmarks() {
  return getImClient().api.main().send(new FetchStickerSetBookmarksEndpoint());
}

export function insertStickerSetBookmark(
  request: InsertStickerSetBookmarkRequest
) {
  return getImClient()
    .api.main()
    .send(new InsertStickerSetBookmarkEndpoint(request));
}

export function deleteStickerSetBookmark(
  request: DeleteStickerSetBookmarkRequest
) {
  return getImClient()
    .api.main()
    .send(new DeleteStickerSetBookmarkEndpoint(request));
}
