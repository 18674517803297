import { SagaIterator } from "redux-saga";
import { call, takeEvery } from 'typed-redux-saga';
import { createUserRoom as createUserRoomAction } from "../actions";
import createUserRoom from "../sagas/createUserRoom";

export default function* watchCreateUserRoom() {
  yield* takeEvery(createUserRoomAction, function* ({ payload }): SagaIterator {
    yield* call(createUserRoom,
      payload.targetProfile,
      payload.myProfileId,
      payload.platform
    );
  });
}
