import _without from "lodash/without";
import { put, select, throttle } from 'typed-redux-saga';
import { orderedRoomIdsSelector } from "../../chatroom/selectors";
import { currentRoomIdSelector } from "../../now/selectors";
import { clearOldMsgs, upsertMsgs } from "../actions";

export default function* watchForLimitMsgs() {
  yield* throttle(10000, upsertMsgs, function* () {
    const currentRoomId = yield* select(currentRoomIdSelector);
    let roomIds = yield* select(orderedRoomIdsSelector);
    roomIds = _without(roomIds, currentRoomId ?? "").slice(0, 20);
    yield* put(clearOldMsgs(roomIds));
  });
}
