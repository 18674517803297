import { ImClient } from "@nearD/im-js-sdk";
import { ImEventTypeNames } from "@nearD/im-js-sdk/lib/events/im.events";
import { eventChannel } from "redux-saga";
import {
  expireQRCode,
  initLogin,
  loginFail,
  loginSuccess,
  logout,
  updateQRCode,
} from "./actions";

interface LogoutMessageEvent extends MessageEvent {
  data: {
    type: "logout";
  };
}

function isLogoutMessageEvent(
  event: MessageEvent
): event is LogoutMessageEvent {
  return event.data?.type === "logout";
}

const createAuthChannel = (imClient: ImClient) => {
  return eventChannel((emit) => {
    imClient.on(ImEventTypeNames.EmailAuthenticated, () => {
      emit(loginSuccess());
    });
    imClient.on(ImEventTypeNames.QRCodeAuthenticated, () => {
      emit(loginSuccess());
    });
    imClient.on(ImEventTypeNames.QRCodeRefresh, (val) => {
      emit(updateQRCode(`${val}/${encodeURIComponent(navigator.userAgent)}`));
    });
    imClient.on(ImEventTypeNames.QRCodeExpired, () => {
      emit(expireQRCode());
    });
    imClient.on(ImEventTypeNames.AuthenticationRequired, () => {
      emit(initLogin());
    });
    imClient.on(ImEventTypeNames.AuthenticationRestored, () => {
      emit(loginSuccess());
    });
    imClient.on(ImEventTypeNames.QRCodeAuthenticatedFailed, (error) => {
      emit(loginFail());
    });
    window.onmessage = (event: MessageEvent) => {
      // this code is for uwants logout action
      if (isLogoutMessageEvent(event)) {
        emit(logout());
      }
    };
    return () => {};
  });
};

export default createAuthChannel;
