import {
  MessageDirection,
  PAGINATION_LIMIT,
} from "@nearD/im-js-sdk/lib/im/messaging.constant";
import _pick from "lodash/pick";
import { all, call, put, select, takeLatest } from "typed-redux-saga";
import getImClient from "utils/imClient";
import { showErrorMsg } from "../../modal/actions";
import { storeIsAllMsgsLoaded, storeIsLoadingMsgs } from "../../now/actions";
import { storeProfiles } from "../../profile/actions";
import { AppState } from "../../rootReducer";
import { fetchOldMsgs, storeMsgs } from "../actions";
import { msgSelector } from "../selectors";
import { transformFirestoreMsgs } from "../transformers";

export default function* watchFetchOldMsgs() {
  yield* takeLatest(fetchOldMsgs, function* ({ payload }) {
    const { roomId } = payload;
    const imClient = getImClient();
    if (!imClient.isSubscribed(roomId)) {
      return;
    }
    const msg = yield* select((state: AppState) => msgSelector(state, payload));
    yield* put(storeIsLoadingMsgs(true));
    try {
      const messages = yield* call(
        imClient.loadPreviousMessage,
        roomId,
        msg?.createdAt ?? null
      );
      const results = transformFirestoreMsgs(
        roomId,
        MessageDirection.PREVIOUS,
        messages
      );
      const profiles = results.map(({ profile }) => profile);
      const roomMsgPayloads = results.map((result) =>
        _pick(result, ["roomId", "msg", "direction"])
      );

      yield* all([
        put(storeProfiles(profiles)),
        put(storeMsgs(roomMsgPayloads)),
        put(storeIsLoadingMsgs(false)),
      ]);
      if (messages.length < PAGINATION_LIMIT) {
        yield* put(storeIsAllMsgsLoaded(true));
      }
    } catch (error) {
      yield* put(showErrorMsg());
      console.error(error);
    }
  });
}
