import { EventChannel, SagaIterator } from "redux-saga";
import { take, put } from "redux-saga/effects";

export const watchChannel = (channel: EventChannel<unknown>) =>
  function* (): SagaIterator {
    while (true) {
      try {
        const action = yield take(channel);
        yield put(action);
      } catch (err) {
        console.error("channel error", err);
      }
    }
  };
