import { fetchPinnedMsg } from "modules/message/actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from 'typed-redux-saga';
import getImClient from "utils/imClient";
import { pinRoomMsg } from "../actions";

export default function* watchPinMessage() {
  yield* takeEvery(
    pinRoomMsg,
    function* ({ payload: { roomId, msgId } }): SagaIterator {
      try {
        yield* call(getImClient().chatroom.pinMessage, roomId, msgId);
        yield* put(fetchPinnedMsg(roomId, msgId));
      } catch (error) {
        console.error(error);
      }
    }
  );
}
