import { call, put, select, takeEvery } from "typed-redux-saga";
import getImClient from "utils/imClient";
import { showErrorMsg } from "../../modal/actions";
import { AppState } from "../../rootReducer";
import { resendMsg, storeMsgStatus } from "../actions";
import { tempMsgSelector } from "../selectors";
import { MessageStatus } from "../view-models";

export default function* watchResendMsg() {
  yield* takeEvery(resendMsg, function* (action) {
    const { roomId, tempId } = action.payload;
    const msg = yield* select((state: AppState) =>
      tempMsgSelector(state, { msgId: tempId })
    );
    if (!msg) {
      return;
    }
    try {
      yield* put(storeMsgStatus(roomId, tempId, MessageStatus.Pending));
      yield* call(getImClient().chatroom.sendMessage, roomId, msg);
    } catch (error) {
      console.error(error);
      yield* put(storeMsgStatus(roomId, tempId, MessageStatus.Failed));
      yield* put(showErrorMsg());
    }
  });
}
