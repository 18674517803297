import { MessageDirection } from "@nearD/im-js-sdk/lib/im/messaging.constant";
import { createReducer } from "@reduxjs/toolkit";
import _pick from "lodash/pick";
import _set from "lodash/set";
import _sortBy from "lodash/sortBy";
import { logout } from "../auth/actions";
import { delRoom } from "../chatroom/actions";
import {
  clearOldMsgs,
  clearReplyMsg,
  setPinnedMsg,
  setReplyMsg,
  storeMsgs,
  storeMsgStatus,
  storePendingMsg,
  storeReadCount,
} from "./actions";
import MessageState from "./state";
import { getMsgId, isPhotoMsg } from "./utils";

const initialState: MessageState = {
  messages: {},
  orderedList: {},
  lastMessage: {},
  pendingMessages: {},
  replyMessage: {},
  pinnedMessages: {},
};

const messageReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(storeMsgs, (state, { payload: { msgPayloads } }) => {
      for (const msgPayload of msgPayloads) {
        const { roomId, msg, direction } = msgPayload;
        const msgId = getMsgId(msg);
        let insertIntoList = true;
        state.messages[roomId] = {
          ...(state.messages[roomId] ?? {}),
          [msgId]: msg,
        };
        state.orderedList[roomId] = state.orderedList[roomId] ?? [];

        // remove pending msg
        if (msg.id && msg.tempId) {
          delete state.pendingMessages[msg.tempId];
          const tempMsg = state.messages[roomId][msg.tempId];
          if (tempMsg && isPhotoMsg(tempMsg.content)) {
            URL.revokeObjectURL(tempMsg.content.payload.uri);
          }
          const index = state.orderedList[roomId].indexOf(msg.tempId);
          if (index >= 0) {
            state.orderedList[roomId][index] = msg.id;
            insertIntoList = false;
          }
        }
        if (insertIntoList) {
          if (direction === MessageDirection.PREVIOUS) {
            state.orderedList[roomId].push(msgId);
          } else {
            state.orderedList[roomId].unshift(msgId);
            state.lastMessage[roomId] = msg;
          }
        }
      }
      const roomIds = new Set(msgPayloads.map((msg) => msg.roomId));
      roomIds.forEach((roomId) => {
        state.orderedList[roomId] = _sortBy(
          Object.values(
            _pick(state.messages[roomId], state.orderedList[roomId])
          ),
          (msg) => msg.createdAt
        )
          .reverse()
          .map(getMsgId);
      });
    })
    .addCase(
      storeReadCount,
      (state, { payload: { roomId, messageId, readCount } }) => {
        state.messages[roomId] = _set(
          state.messages[roomId] ?? {},
          `${messageId}.meta.readCount`,
          readCount
        );
      }
    )
    .addCase(delRoom, (state, { payload: { roomId } }) => {
      delete state.messages[roomId];
      delete state.orderedList[roomId];
      delete state.lastMessage[roomId];
    })
    .addCase(storeMsgStatus, (state, { payload }) => {
      const { roomId, msgId, status } = payload;
      state.messages[roomId][msgId].status = status;
    })
    .addCase(storePendingMsg, (state, { payload: { msg } }) => {
      state.pendingMessages[msg.tempId] = msg;
    })
    .addCase(clearOldMsgs, (state, { payload: { roomIds, keepCount } }) => {
      roomIds.forEach((roomId) => {
        const msgsCount = state.orderedList[roomId]?.length ?? 0;
        if (msgsCount <= 1) {
          return;
        }
        const newOrderedList = state.orderedList[roomId].slice(0, keepCount);
        state.orderedList[roomId] = newOrderedList;
        state.messages[roomId] = _pick(state.messages[roomId], newOrderedList);
      });
    })
    .addCase(setReplyMsg, (state, { payload: { roomId, msg } }) => {
      state.replyMessage[roomId] = msg;
    })
    .addCase(clearReplyMsg, (state, { payload: { roomId } }) => {
      delete state.replyMessage[roomId];
    })
    .addCase(setPinnedMsg, (state, { payload: { roomId, msg } }) => {
      if (!(roomId in state.pinnedMessages)) {
        state.pinnedMessages[roomId] = {};
      }
      state.pinnedMessages[roomId][msg.id ?? ""] = msg;
    })
    .addCase(logout, () => initialState)
);

export default messageReducer;
