import Cookies from "js-cookie";
import { platformUrlsSelector } from "modules/platform/selectors/platformUrlsSelector";
import { call, put, select, takeEvery } from "typed-redux-saga";
import getImClient from "utils/imClient";
import { loginByToken } from "../actions";
import { AuthStorageKey } from "../constants";

export default function* watchLoginByToken() {
  yield* takeEvery(loginByToken, function* ({ payload }) {
    const { token } = payload;
    try {
      const urls = yield* select(platformUrlsSelector);
      yield* call(getImClient().signInWithToken, token);
      Cookies.remove(AuthStorageKey.CustomToken, {
        domain: urls.cookiesDomain,
      });
    } catch (error) {
      const { showErrorMsg } = yield* call(
        () => import("modules/modal/actions")
      );
      yield* put(showErrorMsg());
      console.error(error);
    }
  });
}
