import { createReducer } from "@reduxjs/toolkit";
import _without from "lodash/without";
import { logout } from "../auth/actions";
import {
  storeMySummary,
  storeReceivedGroupInvitation,
  storeSettings,
  storeUserInfo,
  storeUwantsProfileId,
  storeUwantsUserId,
  updateBlockProfileIds,
} from "./actions";
import MeState from "./state";

const initialState: MeState = {
  summary: undefined,
  userInfo: undefined,
  settings: undefined,
  uwantsUserId: undefined,
  receivedGroupInvitation: {},
};

const meReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(storeUserInfo, (state, { payload }) => {
      state.userInfo = payload.userInfo;
      state.settings = payload.settings;
    })
    .addCase(storeSettings, (state, { payload }) => {
      state.settings = payload.settings;
    })
    .addCase(storeUwantsUserId, (state, action) => {
      state.uwantsUserId = action.payload.userId;
    })
    .addCase(storeUwantsProfileId, (state, action) => {
      state.uwantsProfileId = action.payload.profileId;
    })
    .addCase(storeMySummary, (state, action) => {
      state.summary = action.payload.summary;
    })
    .addCase(updateBlockProfileIds, (state, action) => {
      const { type, profileId } = action.payload;
      if (!state.summary?.blockedProfileIds) {
        return;
      }
      if (type === "insert") {
        state.summary.blockedProfileIds = [
          ...new Set([...state.summary.blockedProfileIds, profileId]),
        ];
      } else if (type === "delete") {
        state.summary.blockedProfileIds = _without(
          state.summary.blockedProfileIds,
          profileId
        );
      }
    })
    .addCase(storeReceivedGroupInvitation, (state, { payload }) => {
      state.receivedGroupInvitation[payload.groupId] = payload.isInvited;
    })
    .addCase(logout, () => initialState)
);

export default meReducer;
