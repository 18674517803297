import watchImClientInited from "./watchImClientInited";
import watchSetIsAllowed from "./watchSetIsAllowed";
import watchNotifyMsg from "./watchNotifyMsg";

const notificationWatchers = [
  watchNotifyMsg,
  watchSetIsAllowed,
  watchImClientInited,
];

export default notificationWatchers;
