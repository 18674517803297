import { ChatroomMemberPermissionLevel } from "@nearD/im-js-sdk/lib/api/chatroom/enum/chatroom-member-permission-level.enum";
import { ChatroomType } from "@nearD/im-js-sdk/lib/api/chatroom/enum/chatroom-type.enum";
import { ChatroomMember } from "@nearD/im-js-sdk/lib/api/chatroom/response/base";
import { isUserRoom } from "@nearD/im-js-sdk/lib/utils/room";
import dayjs from "utils/dayjs";
import { GroupRoomVM, RoomVM, UserRoomVM } from "./view-models";

export function isGroupChat(room: RoomVM): room is GroupRoomVM {
  return room.type === ChatroomType.Chat;
}

export function isUserChat(room: RoomVM): room is UserRoomVM {
  return room.type === ChatroomType.User;
}

export function isUserChatId(chatId: string) {
  return isUserRoom(chatId);
}

export function isGroupChatId(chatId: string) {
  return !isUserRoom(chatId);
}

export function createAllRoomMember(
  chatId: string,
  displayName: string
): ChatroomMember {
  return {
    chatId,
    profileId: "all",
    type: ChatroomMemberPermissionLevel.Member,
    displayName,
    avatarUrl: null,
    created_at: 0,
    isVerified: 0,
  };
}

export function isMuted(room: RoomVM, profileId: number) {
  if (isUserChat(room)) {
    return false;
  }
  return (
    (room.mutedMembers[profileId]?.silencedUntil ?? 0) >= dayjs().unix() * 1000
  );
}
