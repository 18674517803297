import { createReducer } from "@reduxjs/toolkit";
import { expireQRCode, logout, storeIsLogged, updateQRCode } from "./actions";
import AuthState from "./state";

const initialState: AuthState = {
  isLogged: undefined,
  qrCode: undefined,
  qrCodeExpired: false,
};

const authReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(updateQRCode, (state, action) => {
      state.qrCodeExpired = false;
      state.qrCode = action.payload.val;
    })
    .addCase(expireQRCode, (state) => {
      state.qrCodeExpired = true;
    })
    .addCase(storeIsLogged, (state, action) => {
      state.isLogged = action.payload.isLogged;
    })
    .addCase(logout, (state) => {
      state = { ...initialState, isLogged: false };
    })
);

export default authReducer;
