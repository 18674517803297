import { SagaIterator } from "redux-saga";
import { call, takeEvery } from 'typed-redux-saga';
import getImClient from "utils/imClient";
import { updateRoomMuteSetting } from "../actions";

export default function* watchUpdateRoomMuteSetting() {
  yield* takeEvery(
    updateRoomMuteSetting,
    function* ({ payload: { roomId, isMute } }): SagaIterator {
      try {
        yield* call(getImClient().chatroom.mute, roomId, isMute);
      } catch (error) {
        console.error(error);
      }
    }
  );
}
