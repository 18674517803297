import {
  FetchFeedRequest,
  FetchFeedEndpoint,
} from "@neard-packages/api-types/feed/endpoint/fetch-feed";
import {
  FetchLocationRequest,
  FetchLocationEndpoint,
} from "@neard-packages/api-types/location/endpoint/fetch-location";
import {
  FetchUrlMetaRequest,
  FetchUrlMetaEndpoint,
} from "@neard-packages/api-types/urlmeta/endpoint/fetch-url-meta";
import getImClient from "utils/imClient";

export function fetchFeed(request: FetchFeedRequest) {
  return getImClient().api.main().send(new FetchFeedEndpoint(request));
}

export function fetchLocation(request: FetchLocationRequest) {
  return getImClient().api.main().send(new FetchLocationEndpoint(request));
}

export function fetchUrlMeta(request: FetchUrlMetaRequest) {
  return getImClient().api.main().send(new FetchUrlMetaEndpoint(request));
}
