import { ImClient } from "@nearD/im-js-sdk";
import { ImEventTypeNames } from "@nearD/im-js-sdk/lib/events/im.events";
import { eventChannel } from "redux-saga";
import { storeReadCount, upsertMsgs, storeMsgStatus } from "./actions";
import { MessageStatus } from "./view-models";

const createMessageChannel = (imClient: ImClient) => {
  return eventChannel((emit) => {
    imClient.on(ImEventTypeNames.RoomNewMessageInBatch, (msgs) => {
      emit(upsertMsgs(msgs));
    });
    imClient.on(ImEventTypeNames.RoomUpdateMessageInBatch, () => {});
    imClient.on(
      ImEventTypeNames.RoomReadMessage,
      (roomId, msgId, readCount, isAllRead) => {
        emit(
          storeReadCount(
            roomId,
            msgId,
            isAllRead ? Number.POSITIVE_INFINITY : readCount
          )
        );
      }
    );
    imClient.on(ImEventTypeNames.SendMessageFailed, (roomId, request) => {
      emit(storeMsgStatus(roomId, request.tempId, MessageStatus.Failed));
    });
    return () => {};
  });
};

export default createMessageChannel;
