import { ImEventTypeNames } from "@nearD/im-js-sdk/lib/events/im.events";
import { takeEvery } from "typed-redux-saga";
import getImClient from "utils/imClient";
import { setIsAllowed } from "../actions";

export default function* watchSetIsAllowed() {
  yield* takeEvery(setIsAllowed, function ({ payload: { isAllowed } }) {
    const token = getImClient().getStore().getAuthStore().getFcmToken();
    if (isAllowed && token === undefined) {
      getImClient().emit(ImEventTypeNames.AskingNotificationPermission);
    }
    if (!isAllowed && token !== undefined) {
      getImClient().deleteToken(token);
    }
  });
}
