import { RemoteConfigs } from "@neard-packages/api-types/config";
import { createAction } from "@reduxjs/toolkit";

export const storeRemoteConfig = createAction(
  "config/StoreRemoteConfig",
  (config: Record<keyof RemoteConfigs, string>) => ({
    payload: {
      config,
    },
  })
);
