import type { MyProfileSummary } from "@neard-packages/api-types/me/model";
import { getUserRoomProfileIds } from "@nearD/toolkit/build/main/lib/chat";
import { navigate } from "@reach/router";
import _difference from "lodash/difference";
import createUserRoom from "modules/chatroom/sagas/createUserRoom";
import { roomSelector } from "modules/chatroom/selectors";
import { isUserChat } from "modules/chatroom/utils";
import { myProfileSummarySelector } from "modules/me/selectors";
import { showErrorMsg } from "modules/modal/actions";
import { platformTypeSelector } from "modules/platform/selectors/platformTypeSelector";
import { fetchProfile } from "modules/profile/api";
import { transformProfiles } from "modules/profile/transformers";
import { AppState } from "modules/rootReducer";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeEvery } from "typed-redux-saga";
import { sendForwardMsg } from "../actions";
import sendForwardMsgHandler from "../sendMsgHandler/sendForwardMsgHandler";

export default function* watchSendForwardMsg() {
  yield* takeEvery(sendForwardMsg, function* (action): SagaIterator {
    // TODO: show error message to user
    const room = yield* select((state: AppState) =>
      roomSelector(state, { roomId: action.payload.roomId })
    );
    const platform = yield* select(platformTypeSelector);
    if (room === undefined) {
      try {
        yield* call(createRoom, platform, action);
      } catch (error) {
        console.error(error);
        if (error instanceof Error) {
          yield* put(showErrorMsg(error.message));
        }
        return;
      }
    }
    if (room !== undefined && isUserChat(room)) {
      try {
        yield* call(fetchProfile, { profileId: room.participantProfileId });
      } catch (error) {
        console.error(error);
        navigate(`/${encodeURIComponent(action.payload.roomId)}`);
        return;
      }
    }
    yield* call(
      sendForwardMsgHandler.sendMsg.bind(sendForwardMsgHandler),
      action
    );
    navigate(`/${encodeURIComponent(action.payload.roomId)}`);
  });
}

function* createRoom(
  platform: string,
  action: ReturnType<typeof sendForwardMsg>
): SagaIterator {
  if (platform !== "nearD") {
    throw new Error("Can't forward message to non-exist room");
  }
  // With assumption that only nearD 1-1 chat need to create room, as only allow forward message to joined group rooms
  const myProfileSummaryMap: Record<number, MyProfileSummary> | undefined =
    yield* select(myProfileSummarySelector);
  const myProfileIds = Object.values(myProfileSummaryMap ?? {}).map(
    (profile) => profile.profileId
  );
  const profileIds = getUserRoomProfileIds(action.payload.roomId);
  const targetProfileId = _difference(profileIds, myProfileIds)[0];
  const myProfileId = profileIds.find(
    (profileId) => profileId !== targetProfileId
  );
  if (!Number.isInteger(targetProfileId) || myProfileId === undefined) {
    throw new Error("Target Profile Id not found");
  }
  try {
    const response = yield* call(fetchProfile, { profileId: targetProfileId });
    const profileVMList = transformProfiles(response.data.profiles);
    yield* call(createUserRoom, profileVMList[0], myProfileId, platform);
  } catch (error) {
    if ((error as any)?.response) {
      throw new Error(
        (error as any)?.response?.message ?? "Target Profile is not found"
      );
    }
    throw error;
  }
}
