import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import watchers from "./watchers";
import { rootReducer } from "./rootReducer";

const sagaMiddleware = createSagaMiddleware();

export const makeStore = (initialState = {}) => {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({ thunk: false, immutableCheck: false }),
      sagaMiddleware,
    ],
  });
  sagaMiddleware.run(watchers);

  return store;
};
