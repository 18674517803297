import { MessageType } from "@nearD/im-js-sdk/lib/api/chatroom/enum/message-type.enum";
import { msgSelector, nextMsgSelector } from "modules/message/selectors";
import { getMsgId } from "modules/message/utils";
import { AppState } from "modules/rootReducer";
import { SagaIterator } from "redux-saga";
import { put, select, takeLatest } from "typed-redux-saga";
import { startPlayingMsg, stopPlayingMsg } from "../actions";
import { currentRoomIdSelector } from "../selectors";

export default function* watchStopPlaying() {
  yield* takeLatest(stopPlayingMsg, function* ({ payload }): SagaIterator {
    if (!payload.isFinished) {
      return;
    }
    const currentRoomId = yield* select(currentRoomIdSelector);
    if (currentRoomId === null) {
      return;
    }
    const currentMsg = yield* select((state: AppState) =>
      msgSelector(state, { msgId: payload.msgId, roomId: currentRoomId })
    );
    const nextMsg = yield* select((state: AppState) =>
      nextMsgSelector(state, { msgId: payload.msgId, roomId: currentRoomId })
    );
    if (currentMsg === undefined || nextMsg === undefined) {
      return;
    }
    if (
      currentMsg.content.type === MessageType.MessageAudioType &&
      nextMsg.content.type === MessageType.MessageAudioType &&
      (nextMsg.id || nextMsg.tempId)
    ) {
      yield* put(startPlayingMsg(getMsgId(nextMsg)));
    }
  });
}
