import { createReducer } from "@reduxjs/toolkit";
import {
  addUnnoticedCount,
  clearUnnoticedCount,
  setIsAllowed,
  storeIsAllowSound,
  storeIsNotificationGranted,
} from "./actions";
import NotificationState from "./state";

function getInitialIsGranted() {
  if (window.Notification === undefined) {
    return false;
  }
  switch (Notification.permission) {
    case "granted":
      return true;
    case "denied":
      return false;
    case "default":
    default:
      return undefined;
  }
}

const NOTIFICATION_ALLOW_SOUND = "notification_allow_sound";
const NOTIFICATION_ALLOWED = "notification_allowed";

const initialState: NotificationState = {
  isGranted: getInitialIsGranted(),
  unnoticedCount: 0,
  isAllowed: localStorage.getItem(NOTIFICATION_ALLOWED) === "1",
  isAllowSound: localStorage.getItem(NOTIFICATION_ALLOW_SOUND) === "1",
};

const notificationReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(
      storeIsNotificationGranted,
      (state, { payload: { isGranted } }) => {
        state.isGranted = isGranted;
      }
    )
    .addCase(addUnnoticedCount, (state) => {
      state.unnoticedCount += 1;
    })
    .addCase(clearUnnoticedCount, (state) => {
      state.unnoticedCount = 0;
    })
    .addCase(setIsAllowed, (state, { payload: { isAllowed } }) => {
      state.isAllowed = isAllowed;
      localStorage.setItem(NOTIFICATION_ALLOWED, isAllowed ? "1" : "0");
    })
    .addCase(storeIsAllowSound, (state, { payload: { isAllowed } }) => {
      state.isAllowSound = isAllowed;
      localStorage.setItem(NOTIFICATION_ALLOW_SOUND, isAllowed ? "1" : "0");
    })
);

export default notificationReducer;
