import { AppState } from "../rootReducer";
import { createSelector } from "@reduxjs/toolkit";
import { isEmptyObject } from "@nearD/toolkit/build/main/lib/val";

const getState = (state: AppState) => state.me;
const getGroupId = (_: AppState, payload: { groupId: number }) =>
  payload.groupId;
const getProfileId = (_: AppState, payload: { profileId: number }) =>
  payload.profileId;

export const myUserInfoSelector = createSelector(
  [getState],
  (state) => state.userInfo
);
export const uwantsUserIdSelector = createSelector(
  [getState],
  (state) => state.uwantsUserId
);
export const uwantsProfileIdSelector = createSelector(
  [getState],
  (state) => state.uwantsProfileId
);

const mySummarySelector = createSelector([getState], (state) => state.summary);

const receivedGroupInvitationSelector = createSelector(
  [getState],
  (state) => state.receivedGroupInvitation
);
export const isHavingProfilesSelector = createSelector(
  [mySummarySelector],
  (summary) => !isEmptyObject(summary?.profiles ?? {})
);

export const myProfileSummarySelector = createSelector(
  [mySummarySelector],
  (summary) => summary?.profiles
);

export const myProfileSelector = createSelector(
  [myProfileSummarySelector, getProfileId],
  (summary, profileId) => summary?.[profileId]
);
export const myGroupMemberSummarySelector = createSelector(
  [mySummarySelector, getGroupId],
  (summary, groupId) => summary?.groups[groupId]
);

export const myMemberRequestSummarySelector = createSelector(
  [mySummarySelector, getGroupId],
  (summary, groupId) => summary?.memberRequests[groupId]
);

export const followingProfileIdsSelector = createSelector(
  [mySummarySelector],
  (summary) => summary?.followingProfileIds
);

export const blockingProfileIdsSelector = createSelector(
  [mySummarySelector],
  (summary) => summary?.blockedProfileIds
);

export const isInvitedByGroupSelector = createSelector(
  [receivedGroupInvitationSelector, getGroupId],
  (groupInvitations, groupId) =>
    groupInvitations[groupId] as boolean | undefined
);

export const settingsSelector = createSelector(
  [getState],
  (state) => state.settings
);
