import { put, takeLeading } from 'typed-redux-saga';
import { storeStickerHistories } from "../../sticker/actions";
import { sendStickerMsg } from "../actions";
import sendStickerMsgHandler from "../sendMsgHandler/sendStickerMsgHandler";

export default function* watchSendStickerMsg() {
  yield* takeLeading(sendStickerMsg, function* (action) {
    yield* put(storeStickerHistories([action.payload.sticker.stickerId]));
    yield sendStickerMsgHandler.sendMsg(action);
  });
}
