import type { Profile as V2Profile } from "@neard-packages/api-types/profile/model";
import { SenderInfo } from "@nearD/im-js-sdk/lib/im/models/base-message";
import { FirestoreProfile } from "@nearD/im-js-sdk/lib/im/models/profile";
import type { ProfileStatusVM, ProfileVM } from "./view-models";

export interface FirestoreProfileTransformResult {
  profile: ProfileVM;
  status: ProfileStatusVM;
}

export const transformFirestoreProfile = (
  profile: FirestoreProfile
): FirestoreProfileTransformResult => ({
  profile: {
    id: profile.profileId,
    name: profile.displayName,
    avatarSrc: profile.avatarUrl ?? undefined,
    updatedAt: profile.updatedAt ? profile.updatedAt / 1000 : undefined,
    deletedAt: null,
  },
  status: {
    id: profile.profileId,
    isOnline: profile.onlineStatus,
    lastOnlineAt: profile.lastOnlineAt ?? null,
  },
});

export const transformProfile = (profile: V2Profile): ProfileVM => ({
  id: profile.profileId,
  name: profile.displayName,
  avatarSrc: profile.avatarUrl ?? undefined,
  nsfwAvatarSrc: profile.blurAvatarUrl ?? undefined,
  description: profile.description ?? undefined,
  feedsCount: profile.feedCount,
  followersCount: profile.followerCount,
  thirdParty: profile?.bindingPlatform ?? undefined,
  isNSFW: profile.isNSFW,
  updatedAt: profile.updated_at ?? undefined,
  deletedAt: profile.deleted_at ?? null,
});

export const transformSenderInfo = (
  sender: SenderInfo,
  profileLastSeenAt: number
): ProfileVM => ({
  id: sender.profileId,
  name: sender.displayName,
  avatarSrc: sender.avatarUrl ?? undefined,
  updatedAt: profileLastSeenAt,
  deletedAt: null,
});

export const transformProfiles = (
  profiles: Record<number, V2Profile>
): ProfileVM[] => Object.values(profiles).map(transformProfile);
