import { SendForwardMessageDto } from "@nearD/im-js-sdk/lib/api/chatroom/dto";
import { RoomVM } from "modules/chatroom/view-models";
import getImClient from "utils/imClient";
import SendMsgHandler from ".";
import { sendForwardMsg } from "../actions";
import { transformForwardMessageDto } from "../transformers";
import { MessageVM } from "../view-models";

type SendForwardMsgAction = ReturnType<typeof sendForwardMsg>;
class SendForwardMsgHandler extends SendMsgHandler<
  SendForwardMsgAction,
  SendForwardMessageDto
> {
  protected async getMsgDto(
    action: SendForwardMsgAction,
    msgTempId: string
  ): Promise<SendForwardMessageDto> {
    const { payload } = action;
    return getImClient().chatroom.sendForwardMessage(
      payload.roomId,
      msgTempId,
      payload.sourceGroupId,
      payload.sourceMsgId,
      payload.sourceRoomId
    );
  }

  protected async transformMsgDto(
    msgDto: SendForwardMessageDto,
    action: SendForwardMsgAction,
    room: RoomVM,
    sendTime: number
  ): Promise<MessageVM> {
    return transformForwardMessageDto(msgDto, room.myProfileId, sendTime);
  }
}

const sendForwardMsgHandler = new SendForwardMsgHandler();

export default sendForwardMsgHandler;
