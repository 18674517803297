import { show } from "@nearD/redux-modal";
import { MODAL_PINNED_MSG } from "modules/modal/types";
import { SagaIterator } from "redux-saga";
import { put, select, takeEvery } from 'typed-redux-saga';
import { stopPlayingMsg } from "../actions";
import { playingMsgIdSelector } from "../selectors";

export default function* watchOnShowModal() {
  yield* takeEvery(
    "@redux-modal/SHOW",
    function* (action: ReturnType<typeof show>): SagaIterator {
      if (action.payload.modal === MODAL_PINNED_MSG) {
        const currentPlayingId = yield* select(playingMsgIdSelector);
        if (currentPlayingId !== null) {
          // set isFinished to false to make sure it wont continue playing next msg
          yield* put(stopPlayingMsg(currentPlayingId, false));
        }
      }
    }
  );
}
