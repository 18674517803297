import { SagaIterator } from "redux-saga";
import { call, select, takeEvery } from "typed-redux-saga";
import { unpinRoom } from "../actions";
import { updatePinnedRoomIds } from "../api";
import { pinnedRoomIdsSelector } from "../selectors";

export default function* watchUnpinRoom() {
  yield* takeEvery(
    unpinRoom,
    function* ({ payload: { roomId } }): SagaIterator {
      try {
        const pinnedRoomIds = yield* select(pinnedRoomIdsSelector);
        const roomIdsSet = new Set(pinnedRoomIds);
        roomIdsSet.delete(roomId);
        yield* call(updatePinnedRoomIds, {
          pinnedChatroomIds: [...roomIdsSet],
        });
      } catch (error) {
        console.error(error);
      }
    }
  );
}
