import { SendForwardMessageDto } from "@nearD/im-js-sdk/lib/api/chatroom/dto/send-forward-message.dto";
import { Quote } from "@nearD/im-js-sdk/lib/api/chatroom/models/message-quote";
import { Sticker } from "@neard-packages/api-types/sticker/model";
import { RoomMessageData } from "@nearD/im-js-sdk/lib/events/im.events";
import { MessageDirection } from "@nearD/im-js-sdk/lib/im/messaging.constant";
import { ForwardMessage } from "@nearD/im-js-sdk/lib/im/models";
import { FirestoreMessage } from "@nearD/im-js-sdk/lib/im/models/base-message";
import { TenorItem } from "@nearD/tenor/dist/types";
import { isNotNil } from "@nearD/toolkit/build/main/lib/val/is-not-nil";
import { transformSenderInfo } from "../profile/transformers";
import { ProfileVM } from "../profile/view-models";
import { isSystemMsg } from "./utils";
import { MessageStatus, MessageVM } from "./view-models";
import { MessageType } from "@nearD/im-js-sdk/lib/api/chatroom/enum/message-type.enum";
import { SendMessageDto } from "@nearD/im-js-sdk/lib/api/chatroom/dto/send-message.dto";
import { SendTextLinkMessageDto } from "@nearD/im-js-sdk/lib/api/chatroom/dto/send-text-link-message.dto";
import { SendTextMessageDto } from "@nearD/im-js-sdk/lib/api/chatroom/dto/send-text-message.dto";
import { SendPhotoMessageDto } from "@nearD/im-js-sdk/lib/api/chatroom/dto/send-photo-message.dto";
import { SendEncryptedPhotoMessageDto } from "@nearD/im-js-sdk/lib/api/chatroom/dto/e2ee/send-encrypted-photo-message.dto";
import { SendEncryptedTenorMessageDto } from "@nearD/im-js-sdk/lib/api/chatroom/dto/e2ee/send-encrypted-tenor-message.dto";
import { SendTenorMessageDto } from "@nearD/im-js-sdk/lib/api/chatroom/dto/send-tenor-message.dto";
import { SendStickerMessageDto } from "@nearD/im-js-sdk/lib/api/chatroom/dto/send-sticker-message.dto";
import { SendEncryptedStickerMessageDto } from "@nearD/im-js-sdk/lib/api/chatroom/dto/e2ee/send-encrypted-sticker-message.dto";

export interface MsgTransformResult {
  roomId: string;
  msg: MessageVM;
  profile: ProfileVM;
  direction: MessageDirection;
}

function extractForwardMsgContent(content: ForwardMessage) {
  const msg = { ...content.forward, senderProfileId: content.forward.senderId };
  return msg;
}

function extractQuoteContent(quote?: Quote) {
  if (!quote) {
    return undefined;
  }
  if (quote.type === MessageType.MessageForwardType) {
    return { ...quote.forward, id: quote.id, senderId: quote.senderId };
  }
  return quote;
}

export function transformFirestoreMsg(msg: FirestoreMessage) {
  const content = msg.message;
  try {
    return {
      message: {
        id: msg.id,
        senderProfileId: Number(msg.sender.profileId),
        content:
          content.type === MessageType.MessageForwardType
            ? extractForwardMsgContent(content)
            : content,
        createdAt: msg.created_at,
        tempId: msg.tempId,
        meta: {
          readCount: msg.meta.read_count,
        },
        version: msg.version,
        forwardContent:
          content.type === MessageType.MessageForwardType
            ? content.payload
            : undefined,
        quoteContent:
          isSystemMsg(content) ||
          content.type === MessageType.MessageForwardType
            ? undefined
            : extractQuoteContent(content.quote),
        status: MessageStatus.Synced,
      },
      profile: transformSenderInfo(msg.sender, msg.updated_at / 1000),
    };
  } catch (error) {
    return null;
  }
}

export function transformFirestoreMsgs(
  roomId: string,
  direction: MessageDirection,
  msgs: FirestoreMessage[]
): MsgTransformResult[] {
  return msgs
    .map(transformFirestoreMsg)
    .filter(isNotNil)
    .map((result) => ({
      roomId,
      msg: result.message,
      profile: result.profile,
      direction,
    }));
}

export const transformRoomMessageDataList = (
  roomMsgs: RoomMessageData[]
): MsgTransformResult[] => {
  return roomMsgs
    .map((roomMsg) => {
      const response = transformFirestoreMsg(roomMsg.rawMessage);
      if (response === null) {
        return null;
      }
      const { message: msg, profile } = response;
      return {
        roomId: roomMsg.roomId,
        msg,
        profile,
        direction: roomMsg.direction,
      };
    })
    .filter(isNotNil);
};

export function transformMessageDto(
  senderProfileId: number,
  createdAt: number,
  msg: SendMessageDto
): Omit<MessageVM, "content"> {
  return {
    id: undefined,
    senderProfileId,
    createdAt,
    meta: {
      readCount: 0,
    },
    version: 2,
    status: MessageStatus.Pending,
    tempId: msg.tempId,
    quoteContent: extractQuoteContent(msg.quote),
  };
}

export const transformTextMessageDto = (
  msg: SendTextMessageDto | SendTextLinkMessageDto,
  senderProfileId: number,
  createdAt: number
): MessageVM => {
  return {
    ...transformMessageDto(senderProfileId, createdAt, msg),
    content: {
      hash: "",
      is_encrypted: false,
      type: MessageType.MessageTextType,
      payload: {
        text: msg.text ?? "",
        subcontent: msg.toPlain().subcontent,
      },
    },
  };
};

export const transformTenorMessageDto = (
  msg: SendTenorMessageDto | SendEncryptedTenorMessageDto,
  item: TenorItem,
  senderProfileId: number,
  createdAt: number
): MessageVM => {
  const preview = item.media[0].tinygif;
  const img = item.media[0].gif;
  return {
    ...transformMessageDto(senderProfileId, createdAt, msg),
    content: {
      hash: "",
      is_encrypted: false,
      type: MessageType.MessagePhotoType,
      payload: {
        text: "",
        subcontent: msg.toPlain().subcontent,
        name: "",
        mime: "image/gif",
        uri: img.url,
        width: img.dims[0],
        height: img.dims[1],
        thumbnail: img.preview,
        coverSmall: preview.url,
      },
    },
  };
};

export const transformPhotoMessageDto = async (
  text: string,
  msg: SendPhotoMessageDto | SendEncryptedPhotoMessageDto,
  img: {
    name: string;
    type: string;
    objectUrl: string;
  },
  senderProfileId: number,
  createdAt: number
): Promise<MessageVM> => {
  const { width, height } = await new Promise((resolve) => {
    const imgEl = new Image();
    imgEl.onload = function () {
      resolve({
        width: imgEl.width,
        height: imgEl.height,
      });
    };
    imgEl.src = img.objectUrl;
  });

  return {
    ...transformMessageDto(senderProfileId, createdAt, msg),
    content: {
      hash: "",
      is_encrypted: false,
      type: MessageType.MessagePhotoType,
      payload: {
        text,
        subcontent: msg.toPlain().subcontent,
        name: img.name,
        mime: img.type,
        uri: img.objectUrl,
        width,
        height,
        thumbnail: img.objectUrl,
        coverSmall: img.objectUrl,
      },
    },
  };
};

export function transformStickerMessageDto(
  msg: SendStickerMessageDto | SendEncryptedStickerMessageDto,
  sticker: Sticker,
  senderProfileId: number,
  createdAt: number
): MessageVM {
  return {
    ...transformMessageDto(senderProfileId, createdAt, msg),
    content: {
      hash: "",
      is_encrypted: false,
      type: MessageType.MessageStickerType,
      payload: {
        ...sticker,
        width: 1000,
        height: 1000,
        fileSize: 100,
      },
    },
  };
}

export function transformForwardMessageDto(
  msg: SendForwardMessageDto,
  senderProfileId: number,
  createdAt: number
): MessageVM {
  const { forward } = msg;
  return {
    ...transformMessageDto(senderProfileId, createdAt, msg),
    content: {
      ...msg.forward,
      hash: "",
      is_encrypted: false,
    },
    forwardContent: {
      chatroomId: forward.chatroomId,
      chatroomName: forward.chatroomName,
      groupId: forward.groupId,
      messageId: forward.id,
    },
  };
}
