import { SagaIterator } from "redux-saga";
import { put, select, takeEvery } from 'typed-redux-saga';
import { imClientInited } from "../../now/actions";
import { setIsAllowed } from "../actions";
import { isNotifAllowedSelector, isNotifGrantedSelector } from "../selectors";

export default function* watchImClientInited() {
  yield* takeEvery(imClientInited, function* (): SagaIterator {
    const isNotifGranted = yield* select(isNotifGrantedSelector);
    const isNotifAllowed = yield* select(isNotifAllowedSelector);
    if (isNotifGranted && isNotifAllowed) {
      yield* put(setIsAllowed(true));
    }
  });
}
