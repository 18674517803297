import { isNotNil } from "@nearD/toolkit/build/main/lib/val/is-not-nil";
import { all, put, takeEvery } from "typed-redux-saga";
import { storeProfiles, storeProfileStatuses } from "../../profile/actions";
import { storeIsRoomListInited, storeRooms, upsertRooms } from "../actions";
import { transformRoom } from "../transformers";

export default function* watchUpsertRooms() {
  yield* takeEvery(upsertRooms, function* (action) {
    const payload = action.payload.rooms.map(transformRoom);
    const profiles = payload.map(({ profile }) => profile).filter(isNotNil);
    const statuses = payload
      .map(({ profileStatus }) => profileStatus)
      .filter(isNotNil);
    const rooms = payload
      .map(({ room: chatroom }) => chatroom)
      .filter(isNotNil);
    yield* all(
      [
        profiles.length > 0 && put(storeProfiles(profiles)),
        statuses.length > 0 && put(storeProfileStatuses(statuses)),
        put(storeRooms(rooms)),
        put(storeIsRoomListInited(true)),
      ].filter(isNotNil)
    );
  });
}
