import { ChatroomType } from "@nearD/im-js-sdk/lib/api/chatroom/enum/chatroom-type.enum";
import { getRoomId } from "@nearD/im-js-sdk/lib/utils/room";
import { navigate } from "@reach/router";
import dayjs from "dayjs";
import { SagaIterator } from "redux-saga";
import { call, put, select } from "typed-redux-saga";
import uwantsClient from "utils/uwantsClient";
import { uwantsUserIdSelector } from "../../me/selectors";
import { storeProfile } from "../../profile/actions";
import { transformProfile } from "../../profile/transformers";
import { ProfileVM } from "../../profile/view-models";
import { storeRooms } from "../actions";
import { UserRoomVM } from "../view-models";

export default function* createUserRoom(
  targetProfile: ProfileVM,
  requestedMyProfileId: number,
  platform: "uwants" | "nearD"
): SagaIterator {
  // profile.id would be uwantsUserId when platform == uwants, need to call uwants api to get nearD profile and roomId
  let roomId: string;
  let profile: ProfileVM;
  let myProfileId = requestedMyProfileId;
  if (platform === "uwants") {
    const uwantsUserId = yield* select(uwantsUserIdSelector);
    const response = yield* call(
      uwantsClient.getRoomIdAndProfileByUwantsId,
      uwantsUserId ?? "",
      targetProfile.id
    );
    roomId = response.roomId;
    profile = transformProfile(response.targetProfile);
    // need to override as default profile may not be the profile binding with uwants
    myProfileId = Number(response.myProfileId);
  } else {
    roomId = getRoomId(`${myProfileId}%${targetProfile.id}`);
    profile = targetProfile;
  }
  const room: UserRoomVM = {
    id: roomId,
    name: profile.name,
    myProfileId,
    memberCount: 2,
    type: ChatroomType.User,
    isTemp: true,
    isMuted: false,
    isEncrypted: false,
    createdAt: dayjs().valueOf(),
    lastMsgAt: undefined,
    avatarSrc: profile.avatarSrc,
    participantProfileId: profile.id,
  };
  yield* put(storeProfile(profile));
  yield* put(storeRooms([room]));
  navigate(`/${encodeURIComponent(roomId)}`);
}
