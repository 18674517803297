import SentJoinGroupRequestToast from "components/Toast/SentJoinGroupRequestToast";
import _pick from "lodash/pick";
import getGroup from "modules/group/sagas/getGroup";
import { fetchMySummary } from "modules/me/watchers";
import { toast } from "react-toastify";
import { call, put, takeEvery } from "typed-redux-saga";
import { showErrorMsg } from "../modal/actions";
import * as actions from "./actions";

function* watchQuitGroup() {
  yield* takeEvery(actions.quitGroup, function* (action) {
    const { quitGroup } = yield* call(() => import("./api/quitGroup"));
    yield* call(quitGroup, _pick(action.payload, ["groupId", "profileId"]));
    yield* call(fetchMySummary);
  });
}

function* watchJoinGroup() {
  yield* takeEvery(actions.joinGroup, function* (action) {
    const { groupId, profileId } = action.payload;
    const group = yield* call(getGroup, groupId);
    if (!group) {
      return;
    }
    try {
      if (group.memberRequest) {
        const { insertMemberRequest } = yield* call(
          () => import("./api/insertMemberRequest")
        );
        yield* call(insertMemberRequest, {
          groupId,
          profileId,
        });
        toast(<SentJoinGroupRequestToast />, { autoClose: false });
      } else {
        const { joinGroup } = yield* call(() => import("./api/joinGroup"));
        yield* call(joinGroup, { groupId, profileId });
      }
      yield* call(fetchMySummary);
    } catch (error) {
      yield* put(showErrorMsg());
      console.error(error);
    }
  });
}

function* watchCancelRequest() {
  yield* takeEvery(actions.cancelRequest, function* (action) {
    try {
      const { cancelMemberRequest } = yield* call(
        () => import("./api/cancelMemberRequest")
      );
      yield* call(cancelMemberRequest, action.payload);
      yield* call(fetchMySummary);
    } catch (error) {
      yield* put(showErrorMsg());
      console.error(error);
    }
  });
}

const groupWatchers = [watchQuitGroup, watchJoinGroup, watchCancelRequest];

export default groupWatchers;
