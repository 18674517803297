import { SagaIterator } from "redux-saga";
import { call, takeEvery } from 'typed-redux-saga';
import getImClient from "utils/imClient";
import { muteMember } from "../actions";

export default function* watchMuteMember() {
  yield* takeEvery(
    muteMember,
    function* ({
      payload: { roomId, targetProfileId, muteHours },
    }): SagaIterator {
      try {
        yield* call(
          getImClient().chatroom.muteMember,
          roomId,
          targetProfileId,
          muteHours
        );
      } catch (error) {
        console.error(error);
      }
    }
  );
}
