import type { UserInfo } from "@neard-packages/api-types/me/model";
import type { UserInfoVM } from "./view-models";

export function transformUserInfo(userInfo: UserInfo): UserInfoVM {
  return {
    id: userInfo.firebaseUid,
    defaultProfileId: userInfo.defaultProfileId,
    email: userInfo.email,
  };
}
