import { SendEncryptedStickerMessageDto } from "@nearD/im-js-sdk/lib/api/chatroom/dto/e2ee/send-encrypted-sticker-message.dto";
import { SendStickerMessageDto } from "@nearD/im-js-sdk/lib/api/chatroom/dto/send-sticker-message.dto";
import { RoomVM } from "modules/chatroom/view-models";
import getImClient from "utils/imClient";
import SendMsgHandler from ".";
import { sendStickerMsg } from "../actions";
import { transformStickerMessageDto } from "../transformers";
import { MessageVM } from "../view-models";

type SendStickerMsgAction = ReturnType<typeof sendStickerMsg>;

class SendStickerMsgHandler extends SendMsgHandler<
  SendStickerMsgAction,
  SendStickerMessageDto | SendEncryptedStickerMessageDto
> {
  protected cooldown = 2000;

  protected getMsgDto(
    action: SendStickerMsgAction,
    msgTempId: string,
    replyMsgId?: string
  ): Promise<SendStickerMessageDto | SendEncryptedStickerMessageDto | null> {
    const { roomId, sticker } = action.payload;
    return getImClient().chatroom.sendStickerMessage(
      roomId,
      msgTempId,
      sticker.stickerSetId,
      sticker.stickerId,
      replyMsgId
    );
  }

  protected async transformMsgDto(
    msgDto: SendStickerMessageDto | SendEncryptedStickerMessageDto,
    action: SendStickerMsgAction,
    room: RoomVM,
    sendTime: number
  ): Promise<MessageVM> {
    return transformStickerMessageDto(
      msgDto,
      action.payload.sticker,
      room.myProfileId,
      sendTime
    );
  }
}

const sendStickerMsgHandler = new SendStickerMsgHandler();
export default sendStickerMsgHandler;
