import { navigate } from "@reach/router";
import { SagaIterator } from "redux-saga";
import { select, takeEvery } from 'typed-redux-saga';
import { currentRoomIdSelector } from "../../now/selectors";
import { delRoom } from "../actions";

export default function* watchDelRoom() {
  yield* takeEvery(delRoom, function* (action): SagaIterator {
    const currentRoomId = yield* select(currentRoomIdSelector);
    if (currentRoomId === action.payload.roomId) {
      navigate("/");
    }
  });
}
