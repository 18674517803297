import type { SensitiveContentSetting } from "@neard-packages/api-types/me/type";
import type { BlockProfileRequest } from "@neard-packages/api-types/me/endpoint/block-profile";
import type { UnblockProfileRequest } from "@neard-packages/api-types/me/endpoint/unblock-profile";
import type {
  AccountSettings,
  MySummary,
} from "@neard-packages/api-types/me/model";
import { createAction } from "@reduxjs/toolkit";
import type { UserInfoVM } from "./view-models";

export const storeUserInfo = createAction(
  "me/StoreUserInfo",
  (userInfo: UserInfoVM, settings: AccountSettings) => ({
    payload: {
      userInfo,
      settings,
    },
  })
);

export const storeSettings = createAction(
  "me/StoreSettings",
  (settings: AccountSettings) => ({
    payload: { settings },
  })
);

export const storeUwantsUserId = createAction(
  "me/StoreUwantsUserId",
  (userId?: string) => ({
    payload: {
      userId,
    },
  })
);

export const storeUwantsProfileId = createAction(
  "me/StoreUwantsProfileId",
  (profileId?: number) => ({
    payload: {
      profileId,
    },
  })
);

export const storeMySummary = createAction(
  "me/StoreMySummary",
  (summary: MySummary) => ({ payload: { summary } })
);

export const blockProfile = createAction(
  "me/BlockProfile",
  (payload: BlockProfileRequest) => ({ payload })
);

export const unblockProfile = createAction(
  "me/UnblockProfile",
  (payload: UnblockProfileRequest) => ({ payload })
);

export const updateBlockProfileIds = createAction(
  "me/UpdateBlockProfileIds",
  (payload: { type: "insert" | "delete"; profileId: number }) => ({ payload })
);

export const storeReceivedGroupInvitation = createAction(
  "me/StoreReceivedGroupInvitation",
  (groupId: number, isInvited: boolean) => ({ payload: { groupId, isInvited } })
);

export const checkIfReceivedGroupInvitations = createAction(
  "me/checkIfReceivedGroupInvitations",
  (groupId: number) => ({ payload: { groupId } })
);

export const updateNSFWSetting = createAction(
  "me/updateNSFWSetting",
  (config: SensitiveContentSetting) => ({ payload: { config } })
);
