import { createReducer } from "@reduxjs/toolkit";
import { logout } from "../auth/actions";
import {
  storeProfiles,
  storeProfileStatuses,
  storeProfileExistence,
} from "./actions";
import ProfileState from "./state";
const initialState: ProfileState = {
  profiles: {},
  statuses: {},
  thirdParties: {},
  existence: {},
};

const profileReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(storeProfiles, (state, action) => {
      const { profiles } = action.payload;
      profiles.forEach((incomingProfile) => {
        const { id } = incomingProfile;
        const originalProfile = state.profiles[id];

        if (
          originalProfile === undefined ||
          incomingProfile.updatedAt === undefined ||
          originalProfile.isNSFW === undefined ||
          (originalProfile.updatedAt !== undefined &&
            incomingProfile.updatedAt > originalProfile.updatedAt)
        ) {
          state.profiles[id] = { ...state.profiles[id], ...incomingProfile };
        }

        if (incomingProfile.thirdParty) {
          state.thirdParties[incomingProfile.id] = incomingProfile.thirdParty;
        }
      });
    })
    .addCase(storeProfileStatuses, (state, { payload }) => {
      const { profileStatuses } = payload;
      for (const status of profileStatuses) {
        state.statuses[status.id] = {
          ...state.statuses[status.id],
          ...status,
        };
      }
    })
    .addCase(
      storeProfileExistence,
      (state, { payload: { profileId, isExist } }) => {
        state.existence[profileId] = isExist;
      }
    )
    .addCase(logout, () => initialState)
);

export default profileReducer;
