import { SendTextLinkMessageDto } from "@nearD/im-js-sdk/lib/api/chatroom/dto/send-text-link-message.dto";
import { SendTextMessageDto } from "@nearD/im-js-sdk/lib/api/chatroom/dto/send-text-message.dto";
import { TextInput } from "@nearD/im-js-sdk/lib/api/chatroom/models/text-input.entity";
import { RoomVM } from "modules/chatroom/view-models";
import getImClient from "utils/imClient";
import SendMsgHandler from ".";
import { sendTextMsg } from "../actions";
import { transformTextMessageDto } from "../transformers";
import { isAllowToSend } from "../utils";
import { getLinksFromText } from "../utils/getLinksFromText";
import { MessageVM } from "../view-models";

type SendTextMsgAction = ReturnType<typeof sendTextMsg>;

class SendTextMsgHandler extends SendMsgHandler<
  SendTextMsgAction,
  SendTextMessageDto | SendTextLinkMessageDto
> {
  protected cooldown = 600;

  protected async getMsgDto(
    action: SendTextMsgAction,
    msgTempId: string,
    replyMsgId?: string
  ): Promise<SendTextMessageDto | SendTextLinkMessageDto | null> {
    const { roomId, plainText, entityRanges } = action.payload;
    if (!isAllowToSend(plainText)) {
      return null;
    }
    const links = getLinksFromText(plainText);
    const imClient = getImClient();
    // TODO: implement trimstart for message content
    const payload: TextInput =
      entityRanges.length > 0
        ? imClient.chatroom.formatText(plainText.trimEnd(), entityRanges)
        : imClient.chatroom.plainText(plainText.trim());
    if (links.length === 0) {
      return imClient.chatroom.sendTextMessage(
        roomId,
        msgTempId,
        payload,
        replyMsgId
      );
    } else {
      return imClient.chatroom.sendTextLinkMessage(
        roomId,
        msgTempId,
        payload,
        links[0].getMatchedText(),
        replyMsgId
      );
    }
  }
  protected async transformMsgDto(
    msgDto: SendTextMessageDto | SendTextLinkMessageDto,
    action: SendTextMsgAction,
    room: RoomVM,
    sendTime: number
  ): Promise<MessageVM> {
    return transformTextMessageDto(msgDto, room.myProfileId, sendTime);
  }
}

const sendTextMsgHandler = new SendTextMsgHandler();
export default sendTextMsgHandler;
