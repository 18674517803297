import { ForwardPayload } from "@nearD/im-js-sdk/lib/api/chatroom/models/message-payload";
import { Quote } from "@nearD/im-js-sdk/lib/api/chatroom/models/message-quote";
import { MessageDirection } from "@nearD/im-js-sdk/lib/im/messaging.constant";
import {
  ForwardMessage,
  Message,
} from "@nearD/im-js-sdk/lib/im/models/message";
import {
  DeprecatedSystemMessage,
  SystemMessage,
} from "@nearD/im-js-sdk/lib/im/models/system-message";

export type MessageVM = {
  // it is necessary as msg sent by current user may not have id
  id?: string;
  content:
    | Exclude<Message, ForwardMessage>
    | SystemMessage
    | DeprecatedSystemMessage;
  tempId?: string;
  senderProfileId: number;
  createdAt: number;
  version: number; //use to distinguish between v1 message and v2 message
  status: MessageStatus;
  meta: {
    readCount: number;
  };
  forwardContent?: ForwardPayload;
  quoteContent?: Quote;
};

export type MessageVMMap = {
  [id: string]: MessageVM;
};

export enum MessageStatus {
  Synced,
  Pending,
  Failed,
}

export type RoomMessagePayload = {
  roomId: string;
  msg: MessageVM;
  direction: MessageDirection;
};
