import { setDeviceMeta } from "@nearD/redux-device/build/main/actions";
import { Router } from "@reach/router";
import { Suspense, useEffect, lazy } from "react";
import ReactGA from "react-ga";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from "./components/LoadingScreen";
import { platformTrackingSelector } from "modules/platform/selectors/platformTrackingSelector";

const Login = lazy(() => import("./pages/Login"));
const MainChat = lazy(() => import("pages/MainChat"));

export default function App() {
  const dispatch = useDispatch();
  const trackingDetail = useSelector(platformTrackingSelector);
  useEffect(() => {
    dispatch(setDeviceMeta(navigator.userAgent));
  }, [dispatch]);
  useEffect(() => {
    if (trackingDetail.ga.length > 0) {
      ReactGA.initialize(trackingDetail.ga);
    }
  }, [trackingDetail]);
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Router>
        <Login path="/login" />
        <MainChat path="/*" />
      </Router>
    </Suspense>
  );
}
