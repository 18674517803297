import getImClient from "utils/imClient";
import { watchChannel } from "utils/saga-utils";
import createNowChannel from "../channel";
import watchSetCurrentRoom from "./watchSetCurrentRoom";
import watchTyping from "./watchTyping";
import watchAnchorToBottom from "./watchAnchorToBottom";
import watchStopPlaying from "./watchStopPlaying";
import watchOnShowModal from "./watchOnShowModal";

const nowWatchers = [
  watchChannel(createNowChannel(getImClient())),
  watchSetCurrentRoom,
  watchTyping,
  watchAnchorToBottom,
  watchStopPlaying,
  watchOnShowModal,
];

export default nowWatchers;
