import { call, put, select, takeLeading } from "typed-redux-saga";
import getImClient from "utils/imClient";
import { fetchMyInfo } from "modules/me/watchers";
import { platformTypeSelector } from "modules/platform/selectors/platformTypeSelector";
import { loginSuccess, storeIsLogged } from "../actions";
import { SagaIterator } from "redux-saga";

export default function* watchLoginSuccess() {
  yield* takeLeading(loginSuccess, function* (): SagaIterator {
    const platform = yield* select(platformTypeSelector);
    yield* put(storeIsLogged(true));
    const imClient = getImClient();
    yield* call(imClient.initCrypto);
    yield* call(imClient.startClient);
    yield* call(fetchMyInfo);
    if (platform === "uwants") {
      const { storeUwantsUserId } = yield* call(
        () => import("modules/me/actions")
      );
      const { default: Cookies } = yield* call(() => import("js-cookie"));
      yield* put(storeUwantsUserId(Cookies.get("nwu")));
    }
  });
}
