import type {
  ApiResponse,
  ErrorResponse,
} from "@nearD/toolkit/build/main/lib/api";
import type { Profile as V2Profile } from "@neard-packages/api-types/profile/model";
import Axios, { AxiosInstance, AxiosResponse } from "axios";
import env from "./env";
import type { ProfileResponse } from "@neard-packages/api-types/profile/response";

class UwantsClient {
  private apiClient: AxiosInstance;
  public constructor() {
    this.apiClient = Axios.create({
      baseURL: `${env.uwantsWebsiteUrl}`,
      timeout: 8000,
      withCredentials: true,
    });
  }

  public searchProfiles = async (
    keyword: string
  ): Promise<ApiResponse<ProfileResponse>> => {
    const response: AxiosResponse<ApiResponse<ProfileResponse>> =
      await this.apiClient.get("ajax.php", {
        params: {
          action: "nd_messenger_searchUser",
          q: keyword,
          api_version: "2",
        },
      });
    return response.data;
  };

  public getMyUwantsProfileId = async (): Promise<number | null> => {
    const response: AxiosResponse<
      | ApiResponse<{
          profileId: string | null;
        }>
      | ErrorResponse
    > = await this.apiClient.get("ajax.php", {
      params: {
        action: "nd_inviteCode_checkStatus",
      },
    });
    const payload = response.data;
    if (payload.status === "error" || payload.status === "fail") {
      throw payload.message;
    } else {
      const { data } = payload as ApiResponse<{
        profileId: string | null;
      }>;
      const result = data.profileId === null ? null : Number(data.profileId);
      if (!Number.isFinite(result)) {
        return null;
      }
      return result;
    }
  };

  public getRoomIdAndProfileByUwantsId = async (
    fromUwantsId: string | number,
    uwantsId: string | number
  ): Promise<{
    roomId: string;
    targetProfile: V2Profile;
    myProfileId: string;
  }> => {
    const response: AxiosResponse<
      | ApiResponse<{
          roomId: string;
          targetProfile: V2Profile;
          myProfileId: string;
        }>
      | ErrorResponse
    > = await this.apiClient.get("ajax.php", {
      params: {
        action: "nd_messenger_getRoomIdByUid",
        fromUid: fromUwantsId,
        targetUid: uwantsId,
        api_version: "2",
      },
    });
    const payload = response.data;
    if (payload.status === "error" || payload.status === "fail") {
      throw payload.message;
    }
    return (
      payload as ApiResponse<{
        roomId: string;
        targetProfile: V2Profile;
        myProfileId: string;
      }>
    ).data;
  };
}

const uwantsClient = new UwantsClient();

export default uwantsClient;
