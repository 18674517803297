import resetRoomUnreadCount from "modules/chatroom/sagas/resetRoomUnreadCount";
import { roomSelector } from "modules/chatroom/selectors";
import { isGroupChatId } from "modules/chatroom/utils";
import { notifyMsg } from "modules/notification/actions";
import { currentRoomIdSelector } from "modules/now/selectors";
import { storeProfiles } from "modules/profile/actions";
import { AppState } from "modules/rootReducer";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeEvery } from "typed-redux-saga";
import { storeMsgs, upsertMsgs } from "../actions";
import { transformRoomMessageDataList } from "../transformers";

export default function* watchUpsertMsgs() {
  yield* takeEvery(upsertMsgs, function* ({ payload }): SagaIterator {
    const dataList = transformRoomMessageDataList(payload.msgs);
    const profiles = dataList.map((data) => data.profile);
    const roomMsgPayloads = dataList.map((data) => ({
      roomId: data.roomId,
      msg: data.msg,
      direction: data.direction,
    }));
    yield* put(storeProfiles(profiles));
    yield* put(storeMsgs(roomMsgPayloads));
    const currentRoomId = yield* select(currentRoomIdSelector);
    if (currentRoomId) {
      const currentRoom = yield* select((state: AppState) =>
        roomSelector(state, { roomId: currentRoomId })
      );
      // MUST use isGroupChatId as it is possible that currentRoom doesn't exist at that time
      if (isGroupChatId(currentRoomId) || currentRoom?.isTemp === false) {
        yield* call(resetRoomUnreadCount, currentRoomId);
      }
    }
    if (roomMsgPayloads.length >= 1) {
      const { roomId, msg } = roomMsgPayloads[0];
      yield* put(notifyMsg(roomId, msg));
    }
  });
}
