import getImClient from "utils/imClient";
import { watchChannel } from "utils/saga-utils";
import createAuthChannel from "../channel";
import watchStoreIsLogged from "./watchStoreIsLogged";
import watchInitLogin from "./watchInitLogin";
import watchLogout from "./watchLogout";
import watchLoginFail from "./watchLoginFail";
import watchLoginSuccess from "./watchLoginSuccess";
import watchLoginByToken from "./watchLoginByToken";
import watchLogin from "./watchLogin";

const authWatchers = [
  watchChannel(createAuthChannel(getImClient())),
  watchLogin,
  watchLoginByToken,
  watchLoginSuccess,
  watchLoginFail,
  watchInitLogin,
  watchLogout,
  watchStoreIsLogged,
];

export default authWatchers;
