import { SagaIterator } from "redux-saga";
import { debounce, put, select } from 'typed-redux-saga';
import { clearOldMsgs } from "../../message/actions";
import { storeAnchorToBottom, storeIsAllMsgsLoaded } from "../actions";
import { currentRoomIdSelector } from "../selectors";

export default function* watchAnchorToBottom() {
  yield* debounce(
    30000,
    storeAnchorToBottom,
    function* ({ payload }): SagaIterator {
      if (!payload.anchorToBottom) {
        return;
      }
      const roomId = yield* select(currentRoomIdSelector);
      if (roomId) {
        yield* put(clearOldMsgs([roomId], 60));
        yield* put(storeIsAllMsgsLoaded(false));
      }
    }
  );
}
