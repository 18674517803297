import { SendMessageDto } from "@nearD/im-js-sdk/lib/api/chatroom/dto/send-message.dto";
import { MessageDirection } from "@nearD/im-js-sdk/lib/im/messaging.constant";
import { roomSelector } from "modules/chatroom/selectors";
import { RoomVM } from "modules/chatroom/view-models";
import { storeAnchorToBottom } from "modules/now/actions";
import { AppState } from "modules/rootReducer";
import { all, call, delay, put, select } from "typed-redux-saga";
import dayjs from "utils/dayjs";
import getImClient from "utils/imClient";
import { clearReplyMsg, storeMsgs, storePendingMsg } from "../actions";
import { replyMsgSelector } from "../selectors";
import { MessageVM } from "../view-models";

interface SendMsgBaseAction {
  payload: {
    roomId: string;
  };
}

export default abstract class SendMsgHandler<
  Action extends SendMsgBaseAction,
  MessageDto extends SendMessageDto
> {
  // amount of time to wait before sending another message, in ms
  protected cooldown = 0;

  protected abstract getMsgDto(
    action: Action,
    msgTempId: string,
    replyMsgId?: string
  ): Promise<MessageDto | null>;

  protected abstract transformMsgDto(
    msgDto: MessageDto,
    action: Action,
    room: RoomVM,
    sendTime: number
  ): Promise<MessageVM>;

  *sendMsg(action: Action) {
    const { roomId } = action.payload;
    const room = yield* select((state: AppState) =>
      roomSelector(state, { roomId })
    );
    if (!room) {
      return;
    }
    const replyMsg = yield* select((state: AppState) =>
      replyMsgSelector(state, { roomId })
    );
    const sendMsgDto = yield* call(
      this.getMsgDto,
      action,
      getImClient().messaging.tempId(),
      replyMsg?.id
    );
    if (sendMsgDto === null) {
      return;
    }
    const pendingMsg = yield* call(
      this.transformMsgDto,
      sendMsgDto,
      action,
      room,
      dayjs.utc().valueOf()
    );
    const msgPayload = {
      roomId,
      msg: pendingMsg,
      direction: MessageDirection.FRESH,
    };
    yield* all([
      put(clearReplyMsg(roomId)),
      put(storePendingMsg(sendMsgDto.toPlain())),
      put(storeMsgs([msgPayload])),
      put(storeAnchorToBottom(true)),
    ]);
    if (this.cooldown > 0) {
      yield* delay(this.cooldown);
    }
  }
}
