import { ImClient } from "@nearD/im-js-sdk";
import env from "./env";

let imClient: ImClient | undefined = undefined;

export default function getImClient() {
  if (imClient === undefined) {
    imClient = new ImClient({
      userAgent: navigator.userAgent,
      firebaseConfig: env.firebaseConfig,
      apiBaseURL: env.apiBaseUrl,
      imApiBaseURL: env.imApiBaseUrl,
      maxRetryCount: 3,
    });
  }
  return imClient;
}
