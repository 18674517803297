import { ImClient } from "@nearD/im-js-sdk";
import { ImEventTypeNames } from "@nearD/im-js-sdk/lib/events/im.events";
import { navigate } from "@reach/router";
import { eventChannel } from "redux-saga";
import { imClientInited } from "./actions";

interface NavigateMessageEvent extends MessageEvent {
  data: {
    type: "navigate";
    roomId: string;
  };
}

function isNavigateEvent(event: MessageEvent): event is NavigateMessageEvent {
  return event.data?.type === "navigate";
}

export default function createNowChannel(imClient: ImClient) {
  return eventChannel((emit) => {
    imClient.on(ImEventTypeNames.ImClientInitialized, () => {
      emit(imClientInited());
    });
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        if (isNavigateEvent(event)) {
          navigate(`/${event.data.roomId}`);
        }
      });
    }
    return () => {};
  });
}
