import { call, put, takeLeading } from "typed-redux-saga";
import getImClient from "utils/imClient";
import { login } from "../actions";

export default function* watchLogin() {
  yield* takeLeading(login, function* ({ payload }) {
    const { email, password } = payload;
    try {
      yield* call(getImClient().signIn, email, password);
    } catch (error) {
      const { showErrorMsg } = yield* call(
        () => import("modules/modal/actions")
      );
      yield* put(showErrorMsg());
      console.error(error);
    }
  });
}
