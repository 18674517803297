import { ChatroomType } from "@nearD/im-js-sdk/lib/api/chatroom/enum/chatroom-type.enum";
import {
  ChatroomState,
  GroupChatroomState,
  UserChatroomState,
} from "@nearD/im-js-sdk/lib/store/modules/room/memory";
import _mapValues from "lodash/mapValues";
import { transformFirestoreProfile } from "../profile/transformers";
import { ProfileStatusVM, ProfileVM } from "../profile/view-models";
import { BaseRoomVM, GroupRoomVM, RoomVM } from "./view-models";

const transformBaseRoom = (room: ChatroomState): BaseRoomVM => ({
  id: room.id,
  name: room.name,
  avatarSrc: room.avatarUrl ?? undefined,
  memberCount: room.total_member,
  myProfileId: room.profileId,
  type: room.type,
  isTemp: false,
  isMuted: !!room.muted,
  isEncrypted: !!room.is_encrypted,
  lastMsgAt: room.lastMessage?.created_at ?? undefined,
  createdAt: room.created_at,
});

function transformUserRoom(
  roomVM: BaseRoomVM,
  room: UserChatroomState
): ChatroomTransformResult {
  const { profile, status: profileStatus } = transformFirestoreProfile(
    room.profileInfo
  );
  return {
    profile,
    profileStatus,
    room: {
      ...roomVM,
      type: ChatroomType.User,
      participantProfileId: room.profileInfo.profileId,
    },
  };
}

const transformGroupRoom = (
  roomVM: BaseRoomVM,
  room: GroupChatroomState
): GroupRoomVM => ({
  ...roomVM,
  type: ChatroomType.Chat,
  ownerProfileId: room.ownerId,
  groupId: room.groupId,
  pinnedMsgIds: room.pinnedMessageIds ?? [],
  isNSFW: room.isNSFW,
  blurAvatarUrl: room.blurAvatarUrl,
  mutedMembers: _mapValues(room.mutedMembers ?? {}, (mutedMember) => ({
    operatorId: mutedMember.operatorId,
    silencedUntil: mutedMember.silenceAt,
  })),
});

export interface ChatroomTransformResult {
  room: RoomVM;
  profile?: ProfileVM;
  profileStatus?: ProfileStatusVM;
}

export function transformRoom(room: ChatroomState): ChatroomTransformResult {
  const roomVM = transformBaseRoom(room);
  switch (room.type) {
    case ChatroomType.User:
      return transformUserRoom(roomVM, room);
    case ChatroomType.Chat:
      return { room: transformGroupRoom(roomVM, room) };
  }
}
