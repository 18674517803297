const env = {
  environment: process.env.NODE_ENV,
  webClientUrl: process.env.REACT_APP_NEARD_WEB_CLIENT_URL ?? "",
  nearDWebsiteUrl: process.env.REACT_APP_NEARD_WEBSITE_URL ?? "",
  nearDMessengerUrl: process.env.REACT_APP_NEARD_MESSENGER_URL ?? "",
  nearDAndroidUrl: process.env.REACT_APP_NEARD_ANDROID_URL ?? "",
  nearDiOSUrl: process.env.REACT_APP_NEARD_IOS_URL ?? "",
  uwantsWebsiteUrl: process.env.REACT_APP_UWANTS_WEBSITE_URL ?? "",
  uwantsLogoutUrl: process.env.REACT_APP_UWANTS_LOGOUT_URL ?? "",
  uwantsLoginFailUrl: process.env.REACT_APP_UWANTS_LOGIN_FAIL_URL ?? "",
  uwantsMessengerUrl: process.env.REACT_APP_UWANTS_MESSENGER_URL ?? "",
  showLoginForm: process.env.REACT_APP_SHOW_LOGIN_FORM === "1",
  tusUrl: process.env.REACT_APP_TUS_URL,
  // TODO: delete
  userAgent: process.env.REACT_APP_USER_AGENT ?? "",
  firebaseConfig: JSON.parse(
    process.env.REACT_APP_FIREBASE_CREDENTIALS as string
  ),
  apiBaseUrl: process.env.REACT_APP_API_URL ?? "",
  imApiBaseUrl: process.env.REACT_APP_IM_API_URL ?? "",
  nearDGA: process.env.REACT_APP_NEARD_GA ?? "",
  uwantsGA: process.env.REACT_APP_UWANTS_GA ?? "",
  tenorKey: process.env.REACT_APP_TENOR_KEY ?? "",
  deeplinkPrefix: process.env.REACT_APP_DEEPLINK_PREFIX ?? "neard",
};

export default env;
