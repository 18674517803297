import { MessageDirection } from "@nearD/im-js-sdk/lib/im/messaging.constant";
import { createReducer } from "@reduxjs/toolkit";
import _keyBy from "lodash/keyBy";
import _omit from "lodash/omit";
import _orderBy from "lodash/orderBy";
import _without from "lodash/without";
import _isEqual from "lodash/isEqual";
import { logout } from "../auth/actions";
import { storeMsgs } from "../message/actions";
import {
  delRoom,
  delRoomMember,
  storeIsRoomListInited,
  storeMemberTyping,
  storeRoomMembers,
  storeRooms,
  storeRoomSetting,
  storeUnreadCounts,
  updatePinnedRooms,
} from "./actions";
import RoomState from "./state";
import { RoomVM } from "./view-models";

const initialState: RoomState = {
  rooms: {},
  orderedList: [],
  unreadCount: {},
  members: {},
  typings: {},
  isInited: false,
  pinnedRoomIds: [],
};

function sortRooms(
  originalOrderedList: string[],
  roomMap: { [id: string]: RoomVM },
  pinnedRoomIds: string[]
) {
  const getRoomSortVal = (room: RoomVM) => room.lastMsgAt ?? room.createdAt;
  const sortedUnPinnedRoomIds = _orderBy(
    Object.values(_omit(roomMap, pinnedRoomIds)),
    getRoomSortVal,
    ["desc"]
  ).map((room) => room.id);
  const newOrderedList = [...pinnedRoomIds, ...sortedUnPinnedRoomIds];
  if (_isEqual(originalOrderedList, newOrderedList)) {
    return originalOrderedList;
  }
  return newOrderedList;
}

const roomReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(storeRooms, (state, action) => {
      state.rooms = {
        ...state.rooms,
        ..._keyBy(action.payload.rooms, "id"),
      };
      for (const room of action.payload.rooms) {
        state.typings[room.id] = [];
      }
      if (state.isInited) {
        state.orderedList = sortRooms(
          state.orderedList,
          state.rooms,
          state.pinnedRoomIds
        );
      }
    })
    .addCase(storeIsRoomListInited, (state, action) => {
      state.isInited = action.payload.isInited;
      state.orderedList = sortRooms(
        state.orderedList,
        state.rooms,
        state.pinnedRoomIds
      );
    })
    .addCase(storeMsgs, (state, { payload: { msgPayloads } }) => {
      const msgs = msgPayloads.filter(
        (payload) =>
          payload.direction === MessageDirection.FRESH &&
          // it is possible that msg comes before chatroom exist
          payload.roomId in state.rooms
      );
      if (msgs.length === 0) {
        return;
      }
      for (const msgPayload of msgs) {
        const { msg, roomId } = msgPayload;
        const isLastMsg =
          (msg?.createdAt ?? 0) > (state.rooms[roomId]?.lastMsgAt ?? 0);
        if (!msg?.createdAt || !isLastMsg) {
          return;
        }
        state.rooms[roomId] = {
          ...state.rooms[roomId],
          id: roomId,
          lastMsgAt: msg.createdAt,
        };
      }
      if (state.isInited) {
        state.orderedList = sortRooms(
          state.orderedList,
          state.rooms,
          state.pinnedRoomIds
        );
      }
    })
    .addCase(storeUnreadCounts, (state, { payload: { counts } }) => {
      for (const count of counts) {
        state.unreadCount[count.roomId] = count.unreadCount;
      }
    })
    .addCase(storeRoomMembers, (state, { payload: { roomId, members } }) => {
      state.members[roomId] = {
        ...(state.members[roomId] ?? {}),
        ..._keyBy(members, "profileId"),
      };
    })
    .addCase(delRoomMember, (state, { payload: { roomId, memberId } }) => {
      delete state.members[roomId][memberId];
    })
    .addCase(delRoom, (state, { payload: { roomId } }) => {
      delete state.rooms[roomId];
      delete state.members[roomId];
      delete state.unreadCount[roomId];
      state.orderedList = _without(state.orderedList, roomId);
    })
    .addCase(
      storeMemberTyping,
      (state, { payload: { roomId, profileId, type } }) => {
        const originalTypings = state.typings[roomId] ?? [];
        const resultArr = Array.from(
          new Set(
            type === "add"
              ? [...originalTypings, profileId]
              : _without(originalTypings, profileId)
          )
        );
        state.typings[roomId] = resultArr;
      }
    )
    .addCase(storeRoomSetting, (state, { payload: { roomId, isMuted } }) => {
      state.rooms[roomId].isMuted = isMuted;
    })
    .addCase(logout, () => initialState)
    .addCase(updatePinnedRooms, (state, { payload: { roomIds } }) => {
      state.pinnedRoomIds = roomIds;
      if (state.isInited) {
        state.orderedList = sortRooms(
          state.orderedList,
          state.rooms,
          state.pinnedRoomIds
        );
      }
    })
);

export default roomReducer;
