import { MessageType } from "@nearD/im-js-sdk/lib/api/chatroom/enum/message-type.enum";
import { WithTextPayload } from "@nearD/im-js-sdk/lib/api/chatroom/models/message-payload";
import {
  EncryptedPhotoMessage,
  Message,
  PhotoMessage,
} from "@nearD/im-js-sdk/lib/im/models/message";
import {
  DeprecatedSystemMessage,
  SystemMessage,
} from "@nearD/im-js-sdk/lib/im/models/system-message";
import { MessageVM } from "./view-models";

export function isSystemMsg(
  msg: Message | SystemMessage | DeprecatedSystemMessage
): msg is SystemMessage | DeprecatedSystemMessage {
  return [
    MessageType.MessageBasicGroupChatCreateType,
    MessageType.MessageChatAddMemberType,
    MessageType.MessageChatChangePhotoType,
    MessageType.MessageChatChangeTitleType,
    MessageType.MessageChatDeleteMemberType,
    MessageType.MessageChatDeletePhotoType,
    MessageType.MessageChatMemberJoinType,
    MessageType.MessageChatMemberQuitType,
    MessageType.MessageChatPromotionType,
    MessageType.MessageChatTransferOwnerType,
    MessageType.MessageTypingType,
    MessageType.MessageChatMuteMemberType,
    MessageType.MessageChatUnMuteMemberType,
    MessageType.MessageChatPinMessageType,
    MessageType.MessageChatBlacklistType,
  ].includes(msg.type);
}

export function isPhotoMsg(
  msg: Message | SystemMessage | DeprecatedSystemMessage
): msg is PhotoMessage | EncryptedPhotoMessage {
  return msg.type === MessageType.MessagePhotoType;
}

export function isMultipleImgMsg(
  msg: Message | SystemMessage | DeprecatedSystemMessage
): msg is PhotoMessage {
  return (
    isPhotoMsg(msg) && !msg.is_encrypted && (msg.mediaList ?? []).length > 1
  );
}

export function getMsgId(msg: MessageVM) {
  return msg.id ?? msg.tempId ?? Math.random().toString();
}

export function isAllowToSend(text: string) {
  const MAX_TEXT_LENGTH = 750;
  return text.trim().length > 0 && text.length <= MAX_TEXT_LENGTH;
}

export function getMsgText(msg: MessageVM) {
  const { content } = msg;
  if (content.is_encrypted) {
    return { text: "", subcontent: undefined };
  }
  switch (content.type) {
    case MessageType.MessageTextType:
    case MessageType.MessageTextLinkType:
    case MessageType.MessagePhotoType:
    case MessageType.MessageVideoType:
    case MessageType.MessageGiftSentType:
      return content.payload as WithTextPayload;
    case MessageType.MessageAudioType:
    case MessageType.MessageStickerType:
    default:
      return { text: "", subcontent: undefined };
  }
}
