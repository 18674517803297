import type { Feed } from "@neard-packages/api-types/feed/model/feed";
import type { Profile as V2Profile } from "@neard-packages/api-types/profile/model";
import type { Group as V2Group } from "@neard-packages/api-types/group/model";
import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../rootReducer";
import type {
  GroupPreferences,
  GroupMember,
} from "@neard-packages/api-types/group/model";
import type { Location as V2Location } from "@neard-packages/api-types/location/model";
import type { URLMeta } from "@neard-packages/api-types/urlmeta/model";
import { Chatroom } from "@nearD/im-js-sdk/lib/api/chatroom/response/base";

const getState = (state: AppState) => state.resource;
const groupsSelector = createSelector([getState], (state) => state.groups);
const groupPreferencesSelector = createSelector(
  [getState],
  (state) => state.groupPreferences
);
const locationsSelector = createSelector(
  [getState],
  (state) => state.locations
);
const profilesSelector = createSelector([getState], (state) => state.profiles);
const feedsSelector = createSelector([getState], (state) => state.feeds);
const membersMapSelector = createSelector(
  [getState],
  (state) => state.groupMembers.membersMap
);
const memberIdsSelector = createSelector(
  [getState],
  (state) => state.groupMembers.memberIds
);
const chatroomsSelector = createSelector(
  [getState],
  (state) => state.chatrooms
);
const pollsSelector = createSelector([getState], (state) => state.polls);
const urlMetasSelector = createSelector([getState], (state) => state.urlMetas);

const idSelector = (_state: AppState, props: { id: number }) => props.id;
const groupIdSelector = (_state: AppState, props: { id: number | string }) =>
  props.id;
const textIdSelector = (_state: AppState, props: { id: string }) => props.id;

export const groupResourceSelector = createSelector(
  [groupsSelector, groupIdSelector],
  (groups, id) => groups[id] as V2Group | undefined | null
);
export const locationResourceSelector = createSelector(
  [locationsSelector, idSelector],
  (locations, id) => locations[id] as V2Location | undefined
);
export const profileResourceSelector = createSelector(
  [profilesSelector, idSelector],
  (profiles, id) => profiles[id] as V2Profile | undefined
);
export const feedResourceSelector = createSelector(
  [feedsSelector, idSelector],
  (feeds, id) => feeds[id] as Feed | undefined
);

export const pollResourceSelector = createSelector(
  [pollsSelector, idSelector],
  (polls, id) => polls[id]
);
export const groupMemberIdsSelector = createSelector(
  [memberIdsSelector, idSelector],
  (memberIds, id) => memberIds[id] as number[] | undefined
);

export const groupPreferencesResourceSelector = createSelector(
  [groupPreferencesSelector, idSelector],
  (preferences, id) => preferences[id] as GroupPreferences | undefined
);

const memberIdSelector = (_state: AppState, props: { memberId: number }) =>
  props.memberId;
export const groupMemberResourceSelector = createSelector(
  [membersMapSelector, idSelector, memberIdSelector],
  (membersMap, id, memberId) =>
    membersMap[id]?.[memberId] as GroupMember | undefined
);

export const urlMetaResourceSelector = createSelector(
  [urlMetasSelector, textIdSelector],
  (urlMetas, url) => urlMetas[url] as URLMeta | undefined | null
);

export const chatroomResourceSelector = createSelector(
  [chatroomsSelector, textIdSelector],
  (chatrooms, id) => chatrooms[id] as Chatroom | undefined
);
