import type { Sticker } from "@neard-packages/api-types/sticker/model";
import { IEntityRange } from "@nearD/im-js-sdk/lib/api/chatroom/models/entity-range.entity";
import type { RoomMessageData } from "@nearD/im-js-sdk/lib/events/im.events";
import type { TenorItem } from "@nearD/tenor/dist/types";
import { createAction } from "@reduxjs/toolkit";
import type {
  MessageStatus,
  MessageVM,
  RoomMessagePayload,
} from "./view-models";

export const upsertMsgs = createAction(
  "message/UpsertMsgs",
  (msgs: RoomMessageData[]) => ({
    payload: {
      msgs,
    },
  })
);

export const storeMsgs = createAction(
  "message/StoreMsgs",
  (msgPayloads: RoomMessagePayload[]) => ({
    payload: { msgPayloads },
  })
);

export const storeReadCount = createAction(
  "message/StoreReadCount",
  (roomId: string, messageId: string, readCount: number) => ({
    payload: { roomId, messageId, readCount },
  })
);

export const fetchOldMsgs = createAction(
  "message/FetchOldMsgs",
  (roomId: string, msgId: string) => ({
    payload: { roomId, msgId },
  })
);

export const fetchPinnedMsg = createAction(
  "message/FetchMsg",
  (roomId: string, msgId: string) => ({
    payload: { roomId, msgId },
  })
);

export const fetchRoomPinnedMsgs = createAction(
  "message/FetchRoomPinnedMsgs",
  (roomId: string) => ({
    payload: { roomId },
  })
);

export const sendTextMsg = createAction(
  "message/SendTextMsg",
  (roomId: string, plainText: string, entityRanges: IEntityRange[]) => ({
    payload: {
      roomId,
      plainText,
      entityRanges,
    },
  })
);

export const sendTextMsgs = createAction(
  "message/SendTextMsgs",
  (
    messages: {
      roomId: string;
      plainText: string;
      entityRanges: IEntityRange[];
    }[]
  ) => ({
    payload: {
      messages,
    },
  })
);

export const sendImgMsg = createAction(
  "message/SendImgMsg",
  (
    roomId: string,
    fileId: string,
    imgName: string,
    imgType: string,
    imgObjectUrl: string
  ) => ({
    payload: {
      roomId,
      fileId,
      img: {
        name: imgName,
        type: imgType,
        objectUrl: imgObjectUrl,
      },
    },
  })
);

export const sendTenorMsg = createAction(
  "message/SendTenorMsg",
  (roomId: string, item: TenorItem) => ({
    payload: {
      roomId,
      item,
    },
  })
);

export const sendStickerMsg = createAction(
  "message/SendStickerMsg",
  (roomId: string, sticker: Sticker) => ({
    payload: { roomId, sticker },
  })
);

export const storeMsgStatus = createAction(
  "message/StoreMsgStatus",
  (roomId: string, msgId: string, status: MessageStatus) => ({
    payload: {
      roomId,
      msgId,
      status,
    },
  })
);

export const storePendingMsg = createAction(
  "message/StorePendingMsg",
  (msg: any) => ({
    payload: {
      msg,
    },
  })
);

export const resendMsg = createAction(
  "message/ResendMsg",
  (roomId: string, tempId: string) => ({
    payload: {
      roomId,
      tempId,
    },
  })
);

export const clearOldMsgs = createAction(
  "message/ClearOldMsgs",
  (roomIds: string[], keepCount: number = 1) => ({
    payload: {
      roomIds,
      keepCount,
    },
  })
);

export const setReplyMsg = createAction(
  "message/SetReplyMsg",
  (roomId: string, msg: MessageVM) => ({
    payload: {
      roomId,
      msg,
    },
  })
);

export const clearReplyMsg = createAction(
  "message/ClearReplyMsg",
  (roomId: string) => ({
    payload: { roomId },
  })
);

export const setPinnedMsg = createAction(
  "message/SetPinnedMsg",
  (roomId: string, msg: MessageVM) => ({
    payload: {
      roomId,
      msg,
    },
  })
);

export const sendForwardMsg = createAction(
  "message/SendForwardMsg",
  (
    roomId: string,
    sourceMsgId: string,
    sourceRoomId: string,
    sourceGroupId: number
  ) => ({
    payload: {
      roomId,
      sourceMsgId,
      sourceRoomId,
      sourceGroupId,
    },
  })
);
