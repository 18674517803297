import { SendEncryptedTenorMessageDto } from "@nearD/im-js-sdk/lib/api/chatroom/dto/e2ee/send-encrypted-tenor-message.dto";
import { SendTenorMessageDto } from "@nearD/im-js-sdk/lib/api/chatroom/dto/send-tenor-message.dto";
import { RoomVM } from "modules/chatroom/view-models";
import getImClient from "utils/imClient";
import SendMsgHandler from ".";
import { sendTenorMsg } from "../actions";
import { transformTenorMessageDto } from "../transformers";
import { MessageVM } from "../view-models";

type SendTenorMsgAction = ReturnType<typeof sendTenorMsg>;

class SendTenorMsgHandler extends SendMsgHandler<
  SendTenorMsgAction,
  SendTenorMessageDto | SendEncryptedTenorMessageDto
> {
  protected cooldown = 2000;

  protected getMsgDto(
    action: SendTenorMsgAction,
    msgTempId: string,
    replyMsgId?: string
  ): Promise<SendTenorMessageDto | SendEncryptedTenorMessageDto | null> {
    const { roomId, item } = action.payload;
    return getImClient().chatroom.sendTenorMessage(
      roomId,
      msgTempId,
      item.id,
      replyMsgId
    );
  }
  protected async transformMsgDto(
    msgDto: SendTenorMessageDto | SendEncryptedTenorMessageDto,
    action: SendTenorMsgAction,
    room: RoomVM,
    sendTime: number
  ): Promise<MessageVM> {
    const { item } = action.payload;
    return transformTenorMessageDto(msgDto, item, room.myProfileId, sendTime);
  }
}

const sendTenorMsgHandler = new SendTenorMsgHandler();
export default sendTenorMsgHandler;
