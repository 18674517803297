import { RemoteConfigs } from "@neard-packages/api-types/config/model";
import { isEmptyObject } from "@nearD/toolkit/build/main/lib/val";
import _mapValues from "lodash/mapValues";
import type { SagaIterator } from "redux-saga";
import { call, put, select } from "typed-redux-saga";
import { storeRemoteConfig } from "./actions";
import * as api from "./api";
import { remoteConfigsSelector } from "./selectors";
export function* getRemoteConfig(): SagaIterator<Record<
  keyof RemoteConfigs,
  string
> | null> {
  let remoteConfigs = yield* select(remoteConfigsSelector);
  if (!isEmptyObject(remoteConfigs ?? {})) {
    return remoteConfigs;
  }
  remoteConfigs = yield* call(fetchRemoteConfig);
  if (remoteConfigs !== null) {
    yield* put(storeRemoteConfig(remoteConfigs));
  }
  return remoteConfigs;
}

function* fetchRemoteConfig(): SagaIterator<Record<
  keyof RemoteConfigs,
  string
> | null> {
  try {
    const response = yield* call(api.fetchRemoteConfigs);
    return _mapValues(response.data, (config) => config.defaultValue.value);
  } catch (error) {
    console.error(error);
    return null;
  }
}
