import { isEmptyObject } from "@nearD/toolkit/build/main/lib/val/is-empty-object";
import { createSelector } from "@reduxjs/toolkit";
import _pickBy from "lodash/pickBy";
import { AppState } from "../rootReducer";

function getState(state: AppState) {
  return state.now;
}

export const roomQueryFilterSelector = createSelector(
  [getState],
  (state) => state.roomQueryFilter
);
export const roomProfilesFilterSelector = createSelector(
  [getState],
  (state) => state.roomProfilesFilter
);

export const hasFilteredProfileSelector = createSelector(
  [roomProfilesFilterSelector],
  (filter) => !isEmptyObject(_pickBy(filter))
);

export const roomTypesFilterSelector = createSelector(
  [getState],
  (state) => state.roomTypesFilter
);
export const currentRoomIdSelector = createSelector(
  [getState],
  (state) => state.currentRoomId
);
export const imClientInitedSelector = createSelector(
  [getState],
  (state) => state.isImClientInited
);
export const isLoadingMsgsSelector = createSelector(
  [getState],
  (state) => state.isLoadingMessages
);
export const isAnchorToBottomSelector = createSelector(
  [getState],
  (state) => state.isAnchorToBottom
);
export const isAllMsgsLoadedSelector = createSelector(
  [getState],
  (state) => state.isAllMessagesLoaded
);
export const playingMsgIdSelector = createSelector(
  [getState],
  (state) => state.playingMsgId
);
export const uiLastUpdatedSelector = createSelector(
  [getState],
  (state) => state.uiLastUpdatedAt
);

export const isRoomQueryOnFocusSelector = createSelector(
  [getState],
  (state) => state.isRoomQueryOnFocus
);
