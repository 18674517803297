import { fetchProfile } from "modules/profile/api";
import { AppState } from "modules/rootReducer";
import { SagaIterator } from "redux-saga";
import { actionChannel, call, put, select, take } from "typed-redux-saga";
import * as actions from "./actions";
import { fetchFeed, fetchLocation, fetchUrlMeta } from "./api";
import * as selectors from "./selectors";

function* watchAddFeedResource(): SagaIterator {
  const channel = yield* actionChannel(actions.addFeedResource);
  while (true) {
    const action: ReturnType<typeof actions.addFeedResource> = yield* take(
      channel
    );
    const { feedId } = action.payload;
    const feed = yield* select((state: AppState) =>
      selectors.feedResourceSelector(state, { id: feedId })
    );
    if (feed) {
      continue;
    }
    try {
      const response = yield* call(fetchFeed, { feedId });
      yield* put(actions.storeFeedResource(response.data));
    } catch (error) {
      console.error(error);
    }
  }
}

function* watchAddGroupResource(): SagaIterator {
  const channel = yield* actionChannel(actions.addGroupResource);
  while (true) {
    const action: ReturnType<typeof actions.addGroupResource> = yield* take(
      channel
    );
    const { groupId } = action.payload;
    try {
      const { fetchGroup } = yield* call(
        () => import("modules/group/api/fetchGroup")
      );
      const response = yield* call(fetchGroup, { groupId });
      yield* put(actions.storeGroupResource(response.data));
    } catch (error) {
      console.error(error);
      yield* put(actions.storeInvalidGroup(groupId));
    }
  }
}

function* watchAddGroupPreferencesResource(): SagaIterator {
  const channel = yield* actionChannel(actions.addGroupPreferencesResource);
  while (true) {
    const action: ReturnType<typeof actions.addGroupPreferencesResource> =
      yield* take(channel);
    const groupId = action.payload.groupId;
    try {
      const { fetchGroupPreferences } = yield* call(
        () => import("modules/group/api/fetchGroupPreferences")
      );
      const response = yield* call(fetchGroupPreferences, {
        groupId,
      });
      yield* put(actions.storeGroupPreferencesResource(groupId, response.data));
    } catch (error) {
      console.error(error);
    }
  }
}

function* watchAddGroupMembersResource(): SagaIterator {
  const channel = yield* actionChannel(actions.addGroupMembersResource);
  while (true) {
    const action: ReturnType<typeof actions.addGroupMembersResource> =
      yield* take(channel);
    const { groupId } = action.payload;
    try {
      const { fetchGroupMembers } = yield* call(
        () => import("modules/group/api/fetchGroupMembers")
      );
      const response = yield* call(fetchGroupMembers, {
        groupId,
      });
      yield* put(actions.storeGroupMemberResource(groupId, response.data));
    } catch (error) {
      console.error(error);
    }
  }
}

function* watchAddLocationResource(): SagaIterator {
  const channel = yield* actionChannel(actions.addLocationResource);
  while (true) {
    const action: ReturnType<typeof actions.addLocationResource> = yield* take(
      channel
    );
    const { locationId } = action.payload;
    const location = yield* select((state: AppState) =>
      selectors.locationResourceSelector(state, { id: locationId })
    );
    if (location) {
      continue;
    }
    try {
      const response = yield* call(fetchLocation, { locationId });
      yield* put(actions.storeLocationResource(response.data));
    } catch (error) {
      console.error(error);
    }
  }
}

function* watchAddProfileResource(): SagaIterator {
  const channel = yield* actionChannel(actions.addProfileResource);
  while (true) {
    const action: ReturnType<typeof actions.addProfileResource> = yield* take(
      channel
    );
    const { profileId, replaceExisted } = action.payload;
    const profile = yield* select((state: AppState) =>
      selectors.profileResourceSelector(state, { id: profileId })
    );
    if (profile && !replaceExisted) {
      continue;
    }
    try {
      const response = yield* call(fetchProfile, { profileId });
      yield* put(actions.storeProfileResource(response.data));
    } catch (error) {
      console.error(error);
    }
  }
}

function* watchAddUrlMetaResource(): SagaIterator {
  const channel = yield* actionChannel(actions.addUrlMetaResource);
  while (true) {
    const action: ReturnType<typeof actions.addUrlMetaResource> = yield* take(
      channel
    );
    const { url } = action.payload;
    const urlMeta = yield* select((state: AppState) =>
      selectors.urlMetaResourceSelector(state, { id: url })
    );
    if (urlMeta) {
      continue;
    }
    try {
      const response = yield* call(fetchUrlMeta, { url });
      yield* put(actions.storeUrlMetaResource(url, response.data));
    } catch (error) {
      yield* put(actions.storeUrlMetaResource(url, null));
      console.error(error);
    }
  }
}

const resourceWatchers = [
  watchAddFeedResource,
  watchAddGroupResource,
  watchAddLocationResource,
  watchAddProfileResource,
  watchAddGroupMembersResource,
  watchAddGroupPreferencesResource,
  watchAddUrlMetaResource,
];

export default resourceWatchers;
