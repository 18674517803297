import { SagaIterator } from "redux-saga";
import {
  call,
  delay,
  race,
  select,
  take,
  takeLeading,
} from "redux-saga/effects";
import getImClient from "utils/imClient";
import {
  sendImgMsg,
  sendStickerMsg,
  sendTenorMsg,
  sendTextMsg,
} from "../../message/actions";
import { typing } from "../actions";
import { currentRoomIdSelector } from "../selectors";

export default function* watchTyping() {
  yield takeLeading(typing, function* (): SagaIterator {
    const imClient = getImClient();
    const currentRoomId = yield select(currentRoomIdSelector);
    if (!currentRoomId) {
      return;
    }
    try {
      yield call(imClient.chatroom.setTyping, currentRoomId);
    } catch (error) {
      console.error(error);
    }
    const { wait, sentMsg, isTyping } = yield race({
      wait: delay(4000),
      sentMsg: take([sendTextMsg, sendImgMsg, sendStickerMsg, sendTenorMsg]),
      update: call(function* () {
        yield delay(3000);
        yield take([typing]);
      }),
    });
    try {
      if (sentMsg || wait) {
        yield call(imClient.chatroom.stopTyping, currentRoomId);
      } else if (isTyping) {
        yield call(imClient.chatroom.setTyping, currentRoomId);
      }
    } catch (error) {
      console.error(error);
    }
  });
}
