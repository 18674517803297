import ResourceState from "./state";
import { createReducer } from "@reduxjs/toolkit";
import {
  storeChatroomResource,
  storeFeedResource,
  storeGroupMemberResource,
  storeGroupPreferencesResource,
  storeGroupResource,
  storeInvalidGroup,
  storeLocationResource,
  storeProfileResource,
  storeUrlMetaResource,
} from "./actions";
import { logout } from "../auth/actions";
import { setCurrentRoom } from "../now/actions";

const initialState: ResourceState = {
  urlMetas: {},
  groups: {},
  groupPreferences: {},
  profiles: {},
  locations: {},
  feeds: {},
  groupMembers: {
    memberIds: {},
    membersMap: {},
  },
  polls: {},
  chatrooms: {},
};

const resourceReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(storeFeedResource, (state, { payload }) => {
      state.feeds = { ...state.feeds, ...payload.response.feeds };
      state.locations = { ...state.locations, ...payload.response.locations };
      state.profiles = { ...state.profiles, ...payload.response.profiles };
      state.groups = { ...state.groups, ...payload.response.groups };
      state.polls = { ...state.polls, ...payload.response.polls };
    })
    .addCase(storeGroupResource, (state, { payload }) => {
      state.groups = { ...state.groups, ...payload.response.groups };
      state.locations = { ...state.locations, ...payload.response.locations };
      Object.values(payload.response.groups).forEach((group) => {
        if (group.privateId) {
          state.groups[group.privateId] = group;
        }
      });
    })
    .addCase(storeInvalidGroup, (state, { payload }) => {
      state.groups[payload.groupId] = null;
    })
    .addCase(storeGroupPreferencesResource, (state, { payload }) => {
      state.groupPreferences[payload.groupId] = payload.preferences;
    })
    .addCase(storeLocationResource, (state, { payload }) => {
      state.locations = { ...state.locations, ...payload.response.locations };
    })
    .addCase(storeProfileResource, (state, { payload }) => {
      state.profiles = { ...state.profiles, ...payload.response.profiles };
    })
    .addCase(storeGroupMemberResource, (state, { payload }) => {
      state.groupMembers.membersMap[payload.groupId] =
        payload.response.groupMembers;
      state.groupMembers.memberIds[payload.groupId] =
        payload.response.profilesIndex;
      state.profiles = { ...state.profiles, ...payload.response.profiles };
    })
    .addCase(storeUrlMetaResource, (state, { payload }) => {
      state.urlMetas[payload.url] = payload.response?.urlMeta ?? null;
      if (!payload.response) {
        return;
      }
      const resources = payload.response.urlMetaTargetObjects;
      state.feeds = { ...state.feeds, ...resources.feeds };
      state.groups = { ...state.groups, ...resources.groups };
      state.locations = { ...state.locations, ...resources.locations };
      state.profiles = { ...state.profiles, ...resources.profiles };
      state.groupPreferences = {
        ...state.groupPreferences,
        ...resources.groupPreferences,
      };
      state.polls = { ...state.polls, ...resources.polls };
      state.chatrooms = { ...state.chatrooms, ...resources.chatrooms };
    })
    .addCase(storeChatroomResource, (state, { payload }) => {
      state.chatrooms[payload.chatroom.id] = payload.chatroom;
    })
    .addCase(logout, () => initialState)
    .addCase(setCurrentRoom, () => initialState)
);

export default resourceReducer;
