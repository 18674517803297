import UnblockProfileToast from "components/Toast/UnblockProfileToast";
import _mapValues from "lodash/mapValues";
import { toast } from "react-toastify";
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLeading,
} from "typed-redux-saga";
import uwantsClient from "utils/uwantsClient";
import { storeRoomProfilesFilter } from "../now/actions";
import { storeProfiles } from "../profile/actions";
import { transformProfiles } from "../profile/transformers";
import * as actions from "./actions";
import * as api from "./api";
import { settingsSelector } from "./selectors";
import { transformUserInfo } from "./transformers";

export function* fetchMyInfo() {
  yield* all([
    call(fetchMyUserInfo),
    call(fetchMyUserProfiles),
    call(fetchMySummary),
  ]);
}

function* fetchMyUserInfo() {
  const { data } = yield* call(api.getUserInfo);
  const userInfo = transformUserInfo(data.userInfo);
  const settings = data.settings;
  yield* put(actions.storeUserInfo(userInfo, settings));
}

// This function is for pre-fetching user profiles for filter display
function* fetchMyUserProfiles() {
  const response = yield* call(api.getProfiles, { page: 1, limit: 30 });
  const profiles = transformProfiles(response.data.profiles);
  yield* put(storeProfiles(profiles));
}

export function* fetchMySummary() {
  const response = yield* call(api.getSummary);
  yield* put(actions.storeMySummary(response.data.summary));
  yield* put(
    storeRoomProfilesFilter(
      _mapValues(response.data.summary.profiles, () => false)
    )
  );
}

function* watchStoreUwantsUserId() {
  yield* takeEvery(actions.storeUwantsUserId, function* ({ payload }) {
    if (!payload.userId) {
      return;
    }
    const uwantsProfileId = yield* call(uwantsClient.getMyUwantsProfileId);
    yield* put(actions.storeUwantsProfileId(uwantsProfileId ?? undefined));
  });
}

function* watchBlockProfile() {
  yield* takeEvery(actions.blockProfile, function* ({ payload }) {
    yield* call(api.blockProfile, payload);
    yield* put(
      actions.updateBlockProfileIds({
        type: "insert",
        profileId: payload.profileId,
      })
    );
  });
}

function* watchUnblockProfile() {
  yield* takeEvery(actions.unblockProfile, function* ({ payload }) {
    yield* call(api.unblockProfile, payload);
    yield* put(
      actions.updateBlockProfileIds({
        type: "delete",
        profileId: payload.profileId,
      })
    );
    toast(<UnblockProfileToast />);
  });
}

function* watchCheckIfReceivedGroupInvitations() {
  yield* takeEvery(
    actions.checkIfReceivedGroupInvitations,
    function* ({ payload }) {
      try {
        const response = yield* call(api.getMyGroupInvitations, {
          groupId: payload.groupId,
        });
        yield* put(
          actions.storeReceivedGroupInvitation(
            payload.groupId,
            response.data.invitationsIndex.length > 0
          )
        );
      } catch (error) {
        yield* put(
          actions.storeReceivedGroupInvitation(payload.groupId, false)
        );
      }
    }
  );
}

function* watchUpdateNSFWSetting() {
  yield* takeLeading(actions.updateNSFWSetting, function* ({ payload }) {
    try {
      const currentSettings = yield* select(settingsSelector);
      if (!currentSettings) {
        return;
      }
      const newSettings = {
        ...currentSettings,
        safeMode: payload.config,
      };
      const response = yield* call(api.updateSettings, newSettings);
      const updatedSettings = response.data.settings;
      if (updatedSettings) {
        yield* put(actions.storeSettings(updatedSettings));
      }
    } catch (error) {
      console.error(error);
    }
  });
}

const meWatchers = [
  watchStoreUwantsUserId,
  watchBlockProfile,
  watchUnblockProfile,
  watchCheckIfReceivedGroupInvitations,
  watchUpdateNSFWSetting,
];
export default meWatchers;
