import { WebLang } from "@nearD/toolkit/build/main/lib/lang";
import dayjs from "dayjs";
import enLocale from "dayjs/locale/en";
import jaLocale from "dayjs/locale/ja";
import cnLocale from "dayjs/locale/zh-cn";
import hkLocale from "dayjs/locale/zh-hk";
import calendar from "dayjs/plugin/calendar";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";

const dayjsLocales = {
  [WebLang.ZH_HK]: "zh-hk",
  [WebLang.ZH_CN]: "zh-cn",
  [WebLang.JA_JP]: "ja",
  [WebLang.EN_US]: "en",
};

const dayjsLocaleStructure: { [locale: string]: Record<string, unknown> } = {
  "zh-hk": { ...hkLocale, formats: { ...hkLocale.formats, L: "M月D日" } },
  "zh-cn": { ...cnLocale, formats: { ...cnLocale.formats, L: "M月D日" } },
  en: { ...enLocale },
  ja: { ...jaLocale, formats: { ...jaLocale.formats, L: "M月D日" } },
};

export function mapLocale(i18nLocale: WebLang): string {
  return dayjsLocales?.[i18nLocale] ?? dayjsLocales[WebLang.EN_US];
}

dayjs.extend(updateLocale);
for (const locale in dayjsLocaleStructure) {
  const structure = dayjsLocaleStructure[locale];
  dayjs.locale(locale);
  dayjs.updateLocale(locale, structure);
}
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(calendar);

export default dayjs;
