import { SagaIterator } from "redux-saga";
import { call, takeEvery } from 'typed-redux-saga';
import getImClient from "utils/imClient";
import { unpinRoomMsg } from "../actions";

export default function* watchUnpinMessage() {
  yield* takeEvery(
    unpinRoomMsg,
    function* ({ payload: { roomId, msgId } }): SagaIterator {
      try {
        yield* call(getImClient().chatroom.unpinMessage, roomId, msgId);
      } catch (error) {
        console.error(error);
      }
    }
  );
}
