import { all, put, takeEvery } from 'typed-redux-saga';
import { call } from "typed-redux-saga";
import getImClient from "utils/imClient";
import { storeProfile } from "../../profile/actions";
import { fetchPinnedMsg, setPinnedMsg } from "../actions";
import { transformFirestoreMsg } from "../transformers";

export default function* watchFetchPinnedMsg() {
  yield* takeEvery(fetchPinnedMsg, function* ({ payload }) {
    const { roomId, msgId } = payload;
    const msg = yield* call(getImClient().chatroom.fetchMessage, roomId, msgId);
    if (msg !== undefined) {
      const result = transformFirestoreMsg(msg);
      if (result === null) {
        return;
      }
      const { message: msgVM, profile } = result;
      yield* all([put(setPinnedMsg(roomId, msgVM)), put(storeProfile(profile))]);
    }
  });
}
