import dayjs from "dayjs";
import { myProfileSummarySelector } from "modules/me/selectors";
import { SagaIterator } from "redux-saga";
import { put, select, takeEvery } from 'typed-redux-saga';
import { storeProfile } from "../../profile/actions";
import { profileSelector } from "../../profile/selectors";
import { transformSenderInfo } from "../../profile/transformers";
import { AppState } from "../../rootReducer";
import { storeMemberTyping, updateMemberTyping } from "../actions";

export default function* watchUpdateMemberTyping() {
  yield* takeEvery(
    updateMemberTyping,
    function* ({ payload: { roomId, sender, type } }): SagaIterator {
      const myProfileSummaryMap = yield* select(myProfileSummarySelector);
      if (sender.profileId in (myProfileSummaryMap ?? {})) {
        return;
      }
      const hasProfile = yield* select((state: AppState) =>
        profileSelector(state, { profileId: sender.profileId })
      );
      if (type === "add" && !hasProfile) {
        const profile = transformSenderInfo(sender, dayjs().unix());
        yield* put(storeProfile(profile));
      }
      yield* put(storeMemberTyping(roomId, sender.profileId, type));
    }
  );
}
