import { AppState } from "../rootReducer";
import { createSelector } from "@reduxjs/toolkit";

const getState = (state: AppState) => state.notification;

export const isNotifGrantedSelector = createSelector(
  [getState],
  (state) => state.isGranted
);

export const unnoticedCountSelector = createSelector(
  [getState],
  (state) => state.unnoticedCount
);

export const isNotifAllowedSelector = createSelector(
  [getState],
  (state) => state.isAllowed
);

export const isNotifSoundAllowedSelector = createSelector(
  [getState],
  (state) => state.isAllowSound
);
