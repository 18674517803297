import { SagaIterator } from "redux-saga";
import { call, takeEvery } from 'typed-redux-saga';
import getImClient from "utils/imClient";
import { unMuteMember } from "../actions";

export default function* watchUnMuteMember() {
  yield* takeEvery(
    unMuteMember,
    function* ({ payload: { roomId, targetProfileId } }): SagaIterator {
      try {
        yield* call(
          getImClient().chatroom.muteMember,
          roomId,
          targetProfileId,
          0
        );
      } catch (error) {
        console.error(error);
      }
    }
  );
}
