import { UrlBuilder } from "@nearD/toolkit/build/main/lib/url/builder";
import env from "./env";

const urlBuilder = new UrlBuilder({
  baseWebClientUrl: env.webClientUrl,
  baseChatUrl: env.nearDMessengerUrl,
  baseSiteUrl: env.nearDWebsiteUrl,
  deepLinkProtocol: env.deeplinkPrefix,
});

export const buildGroupUrl = (groupId: number, privateId?: string | null) =>
  urlBuilder.buildGroupUrl(groupId, privateId ?? undefined).detail;
export const buildProfileUrl = (profileId: number | string) =>
  urlBuilder.buildProfileUrl(Number(profileId)).detail;
export const buildFeedUrl = (feedId: number) =>
  urlBuilder.buildFeedUrl(feedId).detail;
export const buildLocationUrl = (locationId: number) =>
  urlBuilder.buildLocationUrl(locationId).detail;
export const buildHashtagUrl = (text: string) =>
  urlBuilder.buildHashtagUrl(text).detail;
export const buildDownloadUrl = urlBuilder.buildDownloadUrl;
export const buildLoginUrl = urlBuilder.buildLoginUrl;
export const buildChatroomUrl = urlBuilder.buildChatroomUrl;
export const buildUwantsProfileUrlByName = (name: string) =>
  `${
    env.uwantsWebsiteUrl
  }/space.php?action=viewpro&view=home_view&username=${encodeURI(name)}`;
