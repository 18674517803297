import getImClient from "utils/imClient";
import { watchChannel } from "utils/saga-utils";
import createMessageChannel from "../channel";
import watchFetchOldMsgs from "./watchFetchOldMsgs";
import watchFetchPinnedMsg from "./watchFetchPinnedMsg";
import watchFetchRoomPinnedMsgs from "./watchFetchRoomPinnedMsgs";
import watchForLimitMsgs from "./watchForLimitMsgs";
import watchResendMsg from "./watchResendMsg";
import watchSendForwardMsg from "./watchSendForwardMsg";
import watchSendImgMsg from "./watchSendImgMsg";
import watchSendStickerMsg from "./watchSendStickerMsg";
import watchSendTenorMsg from "./watchSendTenorMsg";
import watchSendTextMsg from "./watchSendTextMsg";
import watchSendTextMsgs from "./watchSendTextMsgs";
import watchUpsertMsgs from "./watchUpsertMsgs";

const messageWatchers = [
  watchChannel(createMessageChannel(getImClient())),
  watchUpsertMsgs,
  watchFetchPinnedMsg,
  watchFetchRoomPinnedMsgs,
  watchForLimitMsgs,
  watchFetchOldMsgs,
  watchSendTextMsg,
  watchSendImgMsg,
  watchResendMsg,
  watchSendTenorMsg,
  watchSendStickerMsg,
  watchSendForwardMsg,
  watchSendTextMsgs,
];

export default messageWatchers;
